import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';


export default function Copyright(props) {

    return (
        <Typography className='copyright' variant="body2" color="text.secondary" align="center" {...props}>
          {'Copyright © '}
          <Link color="inherit" href="https://deliverit.ae/">
            Deliverit
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      );
}

