import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import MoneyIcon from '@mui/icons-material/Money';
import { useDispatch } from 'react-redux';
import Translation from '../../utils/Translation';

export default function PaymentMethodBox({value,action}) {

  const dispatch = useDispatch()

  return (
    <> 
           <Link to='/add_request'    
               className='text-undecoration' onClick={() => dispatch(action(0))}>
            <Box >
                <Container  className={`box-request ${value === 0 ? 'active':''}`}>
                  <Grid container spacing={3}>
                      <Grid>
                          <CreditCardIcon className='size-address'  />
                      </Grid>

                      <Grid  xs={10} className="address-name"  >
                    
                        <Typography variant="b" component="h3">
                           <Translation data="request.payment_title_0"  />
                        </Typography>
                    
                      </Grid>

                  </Grid>

                  <Box component='span' className='payment-description'>
                    <Translation data="request.payment_desc_0"  />
                  </Box>
                </Container>  
            </Box>
          </Link>


          <Link to='/add_request'    
               className='text-undecoration' onClick={() => dispatch(action(1))}>
            <Box >
                <Container  className={`box-request ${value === 1 ? 'active':''}`}>
                  <Grid container spacing={3}>
                      <Grid>
                          <MoneyIcon className='size-address'  />
                      </Grid>

                      <Grid  xs={10} className="address-name"  >
                    
                        <Typography variant="b" component="h3">
                           <Translation data="request.payment_title_1"  />
                        </Typography>
                    
                      </Grid>

                  </Grid>

                  <Box component='span' className='payment-description'>

                  <Translation data="request.payment_desc_1"  />

                   </Box>
                </Container>  
            </Box>
          </Link>


          <Link to='/add_request'    
               className='text-undecoration' onClick={() => dispatch(action(2))}>
            <Box >
                <Container  className={`box-request ${value === 2 ? 'active':''}`}>
                  <Grid container spacing={3}>
                      <Grid>
                          <MoneyIcon className='size-address'  />
                      </Grid>

                      <Grid  xs={10} className="address-name"  >
                    
                        <Typography variant="b" component="h3">
                            <Translation data="request.payment_title_2"  />
                        </Typography>
                    
                      </Grid>

                  </Grid>

                  <Box component='span' className='payment-description'>

                  <Translation data="request.payment_desc_2"  />

                   </Box>
                </Container>  
            </Box>
          </Link>
    </>
   
  
  )
}
