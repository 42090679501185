import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import MainTitle from '../../components/headline/MainTitle';
import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/material';
import { Email, Facebook, Instagram, LinkedIn, WhatsApp } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import YouTubeIcon from '@mui/icons-material/YouTube';
import XIcon from '@mui/icons-material/X';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import Translation from '../../utils/Translation';
import { memo } from 'react';

 function Setting() {

  return (
    <Box>
     <MainTitle  title='setting.main-title'  />
   
     
      <Box className="setting" sx={{pt:"2%"}}>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="setting-heading"
        >
         <Translation  data="setting.about"  />

        </AccordionSummary>
        <AccordionDetails>
              <Translation  data="setting.about_title"  />
        <br></br>
        <Translation  data="setting.about_desc"  />
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="setting-heading"
        >
      <Translation  data="setting.contact_us"  />
        </AccordionSummary>
        <AccordionDetails>
          <Typography component="h3" >   <Translation  data="setting.contact_us_title"  /></Typography>
          <Typography component="p">    <Translation  data="setting.contact_us_desc"  /> </Typography>
        </AccordionDetails>
        <AccordionActions>

        <Link to='https://api.whatsapp.com/send?phone=971506824339' target='_blank'>
          <Button  variant="contained" endIcon={<WhatsApp />}>
            <Translation  data="setting.whats_up"  /> 
          </Button>
        </Link>
         <Link to='mailto:info@deliveritapp.ae' target='_blank'>
            <Button variant="contained" className='email_address' endIcon={<Email />}>
            <Translation  data="setting.email_address"  /> 
            </Button>
          </Link>
          
        </AccordionActions>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="setting-heading"
        >
        <Translation  data="setting.social_media"  /> 
        </AccordionSummary>
        <AccordionDetails>

      
        <Stack spacing={2} direction="row" className='social-media'>
        <Link to='https://www.instagram.com/deliverit_uae' target='_blank'>
            <Button variant="contained" endIcon={<Instagram />}>
              <Translation  data="setting.social_media_0"  /> 
            </Button>
          </Link>

          <Link to='https://twitter.com/Deliverit_UAE' target='_blank'>
            <Button variant="contained" className='twitter' endIcon={<XIcon />}>
              <Translation  data="setting.social_media_1"  /> 
            </Button>
          </Link>

          <Link to='https://www.facebook.com/DeliveritApp.AE' target='_blank'>
            <Button variant="contained" endIcon={<Facebook />}>
               <Translation  data="setting.social_media_2"  /> 
            </Button>
          </Link>

          <Link to='https://www.tiktok.com/@deliverit_uae' target='_blank'>
            <Button variant="contained" endIcon={<MusicNoteIcon />} >
               <Translation  data="setting.social_media_3"  />  
            </Button>
          </Link>

          <Link to='https://www.linkedin.com/company/deliveritapplication/' target='_blank'>
            <Button variant="contained" endIcon={<LinkedIn />}>
              <Translation  data="setting.social_media_4"  />  
            </Button>
          </Link>

          <Link to='https://www.youtube.com/@deliveritapp3678' target='_blank'>
            <Button variant="contained" endIcon={<YouTubeIcon />}>
              <Translation  data="setting.social_media_5"  />  
            </Button>
          </Link>
        </Stack>

        </AccordionDetails>
 
      </Accordion>


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="setting-heading"
        >
          <Translation  data="setting.cancel"  />  
        </AccordionSummary>
        <AccordionDetails>
           <Translation  data="setting.cancel_desc"  />  
        </AccordionDetails>

      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
         id="setting-heading"
        >
           <Translation  data="setting.version"  />
        </AccordionSummary>
        <AccordionDetails>
        <Translation  data="setting.version_desc"  /> <Typography component="b"> 1.0.0</Typography>
        </AccordionDetails>
      </Accordion>
      </Box>

     
    </Box>
  );
}

export default memo(Setting);
