import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {  Stack } from '@mui/material';
import {TextField} from '@mui/material';
import ResultSend from '../alert/ResultSend';
import Translation from '../../utils/Translation';


export default function RequestCancelModal({open,handleClose,cancelRequest,feedbackCancel,setFeedbackCancel,msg,error}) {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
      };

  return (
    <>
      <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box sx={{ ...style, width: 500 }} component="form" onSubmit={cancelRequest} className='cancel-form'>
            <h2 id="child-modal-title">  <Translation data="request.feedback"  /> </h2>
    

            <TextField fullWidth label=<Translation data="request.feedback_description"  /> id="package_description" value={feedbackCancel}  onChange = {(e) => setFeedbackCancel(e.target.value)} />

            <ResultSend msg={msg}  error={error?true:false} />

            <Stack direction="row" spacing={2} sx={{mt:'1rem'}}>
                <Button variant="outlined" type='submit' color="error" disabled={feedbackCancel?false:true}>
                <Translation data="request.confirm" />
                </Button>
                <Button variant="outlined" onClick={handleClose}>
                <Translation data="request.close" />
                 </Button> 
          </Stack>
          </Box>
      </Modal>
    </>
  )
}
