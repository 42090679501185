import {useState ,useEffect,useCallback }   from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { useDispatch, useSelector } from 'react-redux';
import {useNavigate} from "react-router-dom";
import Copyright from './Copyright'
import FormHelperText from '@mui/material/FormHelperText'
import {verifyOtpAsync,setStatusVerify,setErrorVerify,sendOtpRequest,setStatus} from '../../services/auth/authSlice';
import LogoAuth from './LogoAuth';
import Translation from '../../utils/Translation'

const defaultTheme = createTheme();

export default function ConfirmOtp() {
  
  const [resend, setResend] = useState(true);    
  const [timer, setTimer] = useState(30);    
  const timeOutCallback = useCallback(() => setTimer(currTimer => currTimer - 1), []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isClick, verificationId,user,statusVerify, errorVerify,status,error} = useSelector(state => state.auth);
  const [otpInput, setOtpInput] = useState('');
  const phoneNumberInput=localStorage.getItem("phoneNumber");
  

  const handleVerifyOtp = (e) =>  {
    e.preventDefault();

    if(!otpInput)
      dispatch(setErrorVerify({errorVerify:"otp is Empty"}));
    else
      dispatch(verifyOtpAsync({verificationId, otpInput}));
  } 

  const editNumber = () =>{
    localStorage.removeItem('verificationId');
    navigate('/login');
  }

  const resendOtp = () => {

    dispatch(sendOtpRequest({phoneNumberInput}));
    setTimer(30);
    handleResend(true);
  }

  const handleResend= (val) => {
      setResend(val);
  }

  // check state for redirect page when open page
  useEffect(()=>{

      if(user) 
        navigate('/');
        
      if((localStorage.getItem("verificationId") == null ) && (!user))
         navigate('/login');
  
  },[user]);

    // timer resend
    useEffect(() => {

    timer > 0 && setTimeout(timeOutCallback, 1000);

    if(timer === 0 || timer <  0){
      clearInterval(timeOutCallback);
      handleResend(false);
      setTimer(0);
    }

  }, [timer, timeOutCallback]);

  // check request status after resend 
  useEffect(()=>{

    if(status === "succeeded"){
      localStorage.setItem('verificationId', verificationId);
      dispatch(setStatus({status:"idle"})); // reset status
    }   

  },[status,dispatch]);
  

  // check request and send to home page or profile
  useEffect(() => {

    if(statusVerify === "succeeded"){
      localStorage.removeItem("verificationId");   // remove verification code
      dispatch(setStatusVerify({statusVerify:"idle"})); // reset status
    }  

  },[statusVerify,dispatch]);



  return (
    (!user) && 
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box className='otp-form'>

          <LogoAuth  />

          <Typography className='form-title' component="h1" variant="b">
             OTP
          </Typography>
  
           <Box component="form" sx={{ mt: 1 , width:"100%" }} onSubmit={handleVerifyOtp} >
           
            <TextField  
            InputProps={{
            startAdornment:    <VpnKeyIcon sx={{  mr: 1, my: 0.5 }} />,
            }}
            onInput = {(e) =>{
               e.target.value = e.target.value.slice(0,6)
            }}
              margin="normal"                              
              fullWidth
              id="otp"
              label={<Translation data="auth.enter_otp" />}
              name="otp"
              autoComplete="otp"
              type='text'
              onChange={e => setOtpInput(e.target.value)}
              autoFocus
            />

             <FormHelperText className='error'>
                 { ((statusVerify && statusVerify === "failed") || (errorVerify))? errorVerify:'' }
                 { ((status && status === "failed") || (error))? error:'' }
             </FormHelperText>

            <Button
              type='submit'
              disabled={isClick}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}>
                <Translation data="auth.confirm"  />
            </Button>

            <Button
              type='submit'
              fullWidth
              onClick={()=>editNumber()}
              variant="outlined">
               <Translation data="auth.edit_number"  />  {localStorage.getItem("phoneNumber")}
            </Button>
        
            
            <Button
              disabled={resend}
              className='resend-button'
              type='button'
              onClick={()=> resendOtp()}
              fullWidth
              variant="contained"
              sx={{ mt: 1, mb: 2 }}>
              <Translation data="auth.resend_to"  /> ({timer})
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
    
  );
}




