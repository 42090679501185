import BoxAddress from '../../components/box/BoxAddress'
import Box from '@mui/material/Box';
import MainTitle from '../../components/headline/MainTitle';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import AddSection from './AddSection';

export default function Address() {

const {isLoading}= useSelector(state => state.address)


  return (
    <>
        <MainTitle  title='address.main-title'  />

        <AddSection   />
       
        {isLoading === true ?   <CircularProgress className='full-preloader-position' />  :
        <Box className='all-address'>
          <BoxAddress /> 
        </Box>
        }
    </>
  )
}
