import React from 'react';
import { Container, Typography } from '@mui/material';

const PageOffline  = () => {

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
      }}
    >
      <Typography variant="h1" component="h1" sx={{ fontSize: '6rem', fontWeight: 'bold' }}>
         Offline
      </Typography>
      <Typography variant="h6" component="p" sx={{ mb: 4 }}>
         Oops, please check your network settings and try again ...
      </Typography>
    </Container>
  );
};

export default PageOffline;