import Navbar from './layout/Navbar'
import { Outlet } from 'react-router-dom'
import Footer from './layout/Footer'
import Container from '@mui/material/Container';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import ChatWidget from './chat/ChatWidget';
import { useDispatch } from 'react-redux';
import {useNavigate} from "react-router-dom";
import {loadProfileAsync,setUserProfile,setLoading, refreshProfileAsync} from '../services/profile/profileSlice';
import PageDriver from './others/PageDriver';
import Profile from './profile/Profile';
import ResultError from '../components/alert/ResultError';
import {getArchiveRequestAsync, getRequestAsync, getRequestUnRatedAsync} from '../services/request/requestSlice';
import ErrorLoadProfile from  './others/ErrorLoadProfile';
import { useState } from 'react';
import { loadAddressAsync } from '../services/address/addressSlice';
import { collection, doc, onSnapshot } from 'firebase/firestore';
import { db } from '../config/firebase-config'; // Adjust the path to your firebase.js file
import { UpdateRequestAfterNotifyAsync } from "../services/request/requestSlice";
import {NoIndexPage,IndexPage} from '../utils/helpers';

export default function Base() {

  const {isLoading,userProfile,statusProfile} = useSelector(state => state.profile);
  const { user} = useSelector(state => state.auth);
  const auth = useSelector(state => state.auth);
  const dynamicState = useSelector(state => state.dynamicConfig);
  const {status,error} = useSelector(state => state.request);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [msg,setMsg]=useState('');
  const handleMsg = (val) =>{
    setMsg(val)
   }

  useEffect(()=>{

    if(user){
      dispatch(setLoading({isLoading:true}));
      dispatch(loadProfileAsync({auth,dynamicState}));  // check find profile
      NoIndexPage(); // add no indexing for seo
    }else{
      dispatch(setUserProfile({userProfile:null}));
      navigate('/login');
      IndexPage(); // add indexing for seo
    }
 

  },[dispatch,user]);

  
    //effect after load profile
    useEffect(()=>{

      if(statusProfile === "succeeded") {

        if(userProfile === 0) { 
          navigate('/profile');  // not find profile redirect to profile page
        }else if(userProfile && userProfile.user_type === 0) { // check user is customer
           // get request review invoked once only
           dispatch(getRequestUnRatedAsync({auth,dynamicState}));

           // get request active 
           dispatch(getRequestAsync({auth,dynamicState})); 

            // get archive active 
           dispatch(getArchiveRequestAsync({auth,dynamicState})); //Archive task

          // get all address
          dispatch(loadAddressAsync({auth,dynamicState})); 
        }
      } 
      
    
  },[statusProfile,dispatch]);


  // effect for all requests + archive request
  useEffect(()=> {

    if(status === "failed")
      handleMsg(error)

  },[status,dispatch]);


 //check find any change in the request,profile
  useEffect(() => {

    if(user) {

    const unsubscribe = onSnapshot(collection(db, `/users/${user?.uid}/digest_next`), (snapshot) => {

      const newData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // check profile
      if(localStorage.getItem("profile_update_id") !== newData[0]?.profile_update_id){

        if(localStorage.getItem("profile_update_id") !== null)
          dispatch(refreshProfileAsync({auth,dynamicState}));
        
        localStorage.setItem("profile_update_id",newData[0]?.profile_update_id);
       
     }

      // check request
      if(newData.length > 0){

        if(!localStorage.getItem("request_update_id")  && newData[0]?.request_update_id !== undefined) {
          localStorage.setItem("request_update_id",newData[0]?.request_update_id);
          return;
        }
    
        if(
          newData[0]?.event === "new_offer_received" ||
          newData[0]?.event === "offer_cancelled" || 
          newData[0]?.event === "status_updated_by_driver"
        ){
            // get the request id
            const req_id = newData[0]?.requestId;

           if(req_id !== null && localStorage.getItem("request_update_id") !== newData[0]?.request_update_id) {
              localStorage.setItem("request_update_id",newData[0]?.request_update_id);
              dispatch(UpdateRequestAfterNotifyAsync({auth,dynamicState,req_id}));
            }
            
         }
         
      }
       

    });
  //  Cleanup listener on unmount
    return () => unsubscribe();

     }

  }, []);

  return (
    <>
       {isLoading? 
       <CircularProgress className='full-preloader-position' />
       :
        <Box>
        {(statusProfile === "failed")   && <ErrorLoadProfile />}

        {(userProfile?.user_type === 1)   && <PageDriver /> }
        
        {(user  && statusProfile === "succeeded") && 
        
         <>
          {userProfile === 0 &&  <Profile  />} 

          {userProfile !== 0 &&  userProfile?.user_type === 0 &&
           <Box>
              <Navbar />
              <Container className='padding-outlet'>
                  <Outlet />
              </Container>
              <ResultError />
              <ChatWidget />
              <Footer />
           </Box>
           }
         </>
         }
        </Box>
       }
    </>
     
  )
}

