
export default function GetPeriod(date) {
      
   let period = '';

    if(date["days"] === 0 && date["hours"] === 0 && date["minutes"] === 0 && date["seconds"] <= 30)
       period = ((localStorage.getItem("i18nextLng") === "ar")  ? " بهذه اللحظة " : " at this moment ");
    else{

        period += ((localStorage.getItem("i18nextLng") === "ar")  ?" منذ ":"");

        period += ((localStorage.getItem("i18nextLng") === "ar")  ?"حوالي " :"about ");

        if(date["days"] > 0)
            period += date["days"]+ ((localStorage.getItem("i18nextLng") === "ar")  ?" يوم " :" days ");

       else if(date["hours"] > 0)
         period +=  date["hours"]+ ((localStorage.getItem("i18nextLng") === "ar")  ?" ساعة " :" hours ");

       else if(date["minutes"] > 0)
         period +=  date["minutes"]+ ((localStorage.getItem("i18nextLng") === "ar")  ?" دقيقة " :" minutes ");
       
       else if(date["days"] === 0 && date["hours"] === 0 && date["minutes"] === 0)
         period += date["seconds"]+ ((localStorage.getItem("i18nextLng") === "ar")  ?" ثانية " :" seconds ");

        period += (localStorage.getItem("i18nextLng") !== "ar")  ?"ago":"";
    }

  return (period)
}
