import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { getStripPk,getTestStripPk } from '../../utils/helpers';
import CheckoutForm from '../payment/CheckoutForm';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const loadStripeConditionally = async () => {
  if (navigator.onLine) {
    try {
      const stripe = await loadStripe(getStripPk());
      return stripe;
    } catch (error) {
      console.error('Error loading Stripe:', error);
      return null;
    }
  } else {
    console.warn('Internet connection is offline. Stripe will not be loaded.');
    return null;
  }
};

const stripePromise =  loadStripeConditionally();

export default function FormPayment({clientSecret,showFormPayment,setShowFromPayment,AcceptOffer}) {

  const handleClose = () => setShowFromPayment(false);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };

  const AcceptOfferAfterPayment = (offerIdx) =>
  {
    AcceptOffer(offerIdx);
  }

  return (
    <div>
      <Modal
        open={showFormPayment}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
        <Elements stripe={stripePromise}  options={options}>
           <CheckoutForm
            handleClose = {handleClose} 
            AcceptOfferAfterPayment= {AcceptOfferAfterPayment}
           />
       </Elements>
        </Box>
      </Modal>
    </div>
  );
}