import { differentDate } from '../../utils/helpers';
import GetPeriod from '../date/GetPeriod';
import Avatar from '@mui/material/Avatar';


export default function ChatMessage(props) {
    const { message, sendBy,time } = props.message;
    const date = differentDate(new Date(time));
    const messageClass = sendBy === props.user_id ? 'sent' : 'received';
    
    return (<>
      <div className={`message ${messageClass}`}>
         <Avatar className='img-chat' alt="Remy Sharp" src= {messageClass === 'sent'?props.userImg:props.driverImg} />
         <div className='mb-1'>
         <p>
          {
            (message.includes("[Chat Image]")) ? 
              <img loading="lazy"  className='send-img-chat' src={message.slice(13, message.length-1)}   />
            : <>{message}</>
          }
         </p>     
           <span className='date'>{GetPeriod(date)}</span>
         </div>
         
      </div>
    </>)
}
