import * as React from 'react';
import { useEffect } from 'react';
import Login from './pages/auth/Login';
import ConfirmOtp from './pages/auth/ConfirmOtp';
import Home from './pages/home/Home';
import  {Routes,Route} from 'react-router-dom';
import './assets/scss/layout.scss';
import './assets/scss/Auth.scss';
import './assets/scss/Profile.scss';
import './assets/scss/Address.scss';
import './assets/scss/AddRequest.scss';
import './assets/scss/Home.scss';
import './assets/scss/Global.scss';
import './assets/scss/Chat.scss';
import './assets/scss/Setting.scss';
import './assets/scss/Arabic.scss';
import './assets/scss/Responsive.scss';
import Base from './pages/Base'
import PageNotFound from './pages/others/PageNotFound ';
import { useDispatch, useSelector } from 'react-redux';
import {fetchDynamicConfigurations} from './services/dynamic_config/dynamic_config_service'; // Assuming you have a service to fetch dynamic configurations
import { setDynamicConfigurations } from './services/dynamic_config/dynamicConfigSlice'; // Assuming you have a slice for dynamic configurations
import AddRequest from './pages/add_request/AddRequest';
import Address from './pages/address/Address';
import Setting from  './pages/setting/Setting';
import Profile from './pages/profile/Profile';
import { Box, CircularProgress } from '@mui/material';
import PageOffline from './pages/others/PageOffline';
import { setIsOnline } from './services/globalSlice';
import {setPageNotFound} from './services/globalSlice';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { loadStyle } from './utils/helpers';

const useNetworkStatus = () => {
  const {isOnline} =  useSelector(state => state.global);
  const dispatch = useDispatch();

  const updateNetworkStatus = () => {
    dispatch(setIsOnline(navigator.onLine));
  };

  //   sometimes, the load event does not trigger on some browsers, that is why manually calling updateNetworkStatus on initial mount
  useEffect(() => {
    updateNetworkStatus();
  }, []);

  useEffect(() => {
    window.addEventListener("load", updateNetworkStatus);
    window.addEventListener("online", updateNetworkStatus);
    window.addEventListener("offline", updateNetworkStatus);

    return () => {
        window.removeEventListener("load", updateNetworkStatus);
        window.removeEventListener("online", updateNetworkStatus);
        window.removeEventListener("offline", updateNetworkStatus);
    };
  }, [navigator.onLine]);

  return { isOnline };
};


export default function App() {

  const {isLoading} = useSelector(state => state.auth);
  const {pageNotFound} =  useSelector(state => state.global);
  const dispatch = useDispatch();
  const { isOnline } = useNetworkStatus();
  const allLinks = ['/login','/confirm-otp','/','/add_request','/address','/setting','/profile','/logout'];
  const [language, setLanguage] = useState(localStorage.getItem("i18nextLng"));
  let location = useLocation(); 


  useEffect(() => {

    fetchDynamicConfigurations()
    .then(configurations => {
      dispatch(setDynamicConfigurations(configurations));
    })
    .catch(error => {
      console.error('Error fetching dynamic configurations:', error);
    });


    // check service notification is worker
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
        }).catch((error) => {
          console.log('Service Worker registration failed:', error);
        });
    }

     // check find page in app
     dispatch(setPageNotFound(false)); // reset page 404

    if(!allLinks.includes(location.pathname))
      dispatch(setPageNotFound(true));

      // load style arabic or english
      loadStyle(language);

  }, []);


  return(isOnline?
      <>
        {pageNotFound ? <PageNotFound /> : 
        <Box className="App">
          <div id='recaptcha-container' style={{padding:"1rem"}}></div>
        { isLoading === true &&  <CircularProgress className='full-preloader-position' /> }
        { isLoading === false &&
        <Routes>
                <Route path='/login' element={<Login />} />
                <Route path='/confirm-otp' element={<ConfirmOtp />} />
              <Route  element={<Base />}>
                <Route path='/' index  element={<Home/>} />
                <Route path='/add_request'  element={<AddRequest/>} />
                <Route path='/address'  element={<Address/>} />
                <Route path='/setting'  element={<Setting/>} />
                <Route path='/profile'  element={<Profile/>} />
                <Route path="*" element={<PageNotFound/>} />
              </Route>
        </Routes>}
        </Box>
        }
      </>
      :
      <PageOffline />
  )
}

