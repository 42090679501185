import React from 'react'

export default function RequestImg({url}) {
  return (
    <img  loading="lazy" 
        src={url??''}
        loading="lazy"
        className='img-request'
        />
  )
}
