import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setPageNotFound } from '../../services/globalSlice';

const PageNotFound  = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleGoHome = () => {

    navigate('/');
    dispatch(setPageNotFound(false))
  };

 
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
      }}
    >
      <Typography variant="h1" component="h1" sx={{ fontSize: '6rem', fontWeight: 'bold' }}>
        404
      </Typography>
      <Typography variant="h6" component="p" sx={{ mb: 4 }}>
        Oops! The page you're looking for doesn't exist.
      </Typography>
      <Button variant="contained" color="primary" onClick={() => handleGoHome()}>
        Go Home
      </Button>
    </Container>
  );
};

export default PageNotFound;