import * as React from 'react';
import Button from '@mui/material/Button';
import { useState } from 'react';
import RequestDetails from './RequestDetails';
import Translation from '../../utils/Translation';

export default function ShowRequest({data}) {

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button size='small' variant="contained"  className='mr-0-5 table-button'  onClick={()=>handleClickOpen()}>
           <Translation data="request.details"  />
       </Button>
       <RequestDetails data={data} open={open} handleClose={handleClose} />
    </>
  );
}

//photo_url
