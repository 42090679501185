import * as React from 'react';
import SliderHome from '../../components/slider/SliderHome';
import Request from './Request';
import MainTitle from '../../components/headline/MainTitle';
export default function Home() {

  return (
  <>
    <MainTitle   title="home.main-title"  />
  
    <SliderHome />

    <Request />

  </>);
}
