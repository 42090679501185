import ApiService from '../api/api_service';
import {ApiException} from '../../utils/exceptions';

const RequestService = {

    async addRequest({auth,dynamicState,data}) {
     
        const path = '/v1/requests';
        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
       
        const options = {"dynamicState":dynamicState,"reformatData":true};
 
        try {

            const response = await ApiService.post(path,headers,data,options);
            const request = response.data; 

            return request
        
            } catch(error) {
                throw new ApiException(error)
            }
      
    },


    async getRequest({auth,dynamicState}) {
     
        const path = '/v4/requests/active';
        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
       
        const options = {"dynamicState":dynamicState,"reformatData":true};
        const data = {};
 
        try {

            const response = await ApiService.get(path,headers,data,options);
            const request = response.data; 

            return request
        
            } catch(error) {
                throw new ApiException(error)
            }
      
    },
    
    async cancelRequest({auth,dynamicState,data}) {
     
        const path = '/v1/requests/cancel';
        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
       
        const options = {"dynamicState":dynamicState,"reformatData":true};
 
        try {

            const response = await ApiService.post(path,headers,data,options);
            const request = response.data; 

            return request
        
            } catch(error) {
                throw new ApiException(error)
            }
      
    },

    async getArchiveRequest({auth,dynamicState}) {
     
        const path = '/v4/requests/archive';
        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
       
        const options = {"dynamicState":dynamicState,"reformatData":true};
        const data = {};
 
        try {

            const response = await ApiService.get(path,headers,data,options);
            const request = response.data; 

            return request
        
            } catch(error) {
                throw new ApiException(error)
            }
      
    },


    async getPathAndTimeEst(pt1,pt2) {

        const url = "/maps/api/directions/json?";
        const key = "key=AIzaSyA5qj88Gbv3jCtM6V5NoPDHwo13Xu95OS4"
        const origin=`&origin= ${pt1}`;
        const destination =`&destination=${pt2}`;
        const path = url+key+origin+destination;

        const headers = {};
        const dynamicState = {"prodApiServerBaseUrl":"https://maps.googleapis.com"}
        const options = {"dynamicState":dynamicState,"reformatData":false};
        const data = {};
     
        try {
            const response = await ApiService.get(path,headers,data,options);
            const request = response.data; 

            return request
        
            } catch(error) {
                throw new ApiException(error)
        }

    },

    async acceptOffer({auth,dynamicState,object}) {
     
        const path = '/v1/offers/approve';
        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
        const options = {"dynamicState":dynamicState,"reformatData":true};
       
        try {

            const response = await ApiService.put(path,headers,object,options);
            const request = response.data; 

            return request
        
            } catch(error) {
                throw new ApiException(error)
            }
      
    },

    async rejectOffer({auth,dynamicState,offer_id,object}) {
     
        const path = `/v4/offers/${offer_id}/reject`;
        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
        const options = {"dynamicState":dynamicState,"reformatData":true};
 
        try {
            const response = await ApiService.patch(path,headers,object,options);
            const request = response.data; 

            return request
        
            } catch(error) {
                throw new ApiException(error)
            }
      
    },
     

    async applyCoupon({auth,dynamicState,data}) {

        const path = `/v4/offers/apply_coupon?code=${data.code}&amount=${data.amount}
                      &offer_id=${data.offer_id}&request_id=${data.request_id}`;
        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
       
        const options = {"dynamicState":dynamicState,"reformatData":false};

        try {

            const response = await ApiService.get(path,headers,{},options);
            const request = response.data; 

            return request
        
            } catch(error) {
                throw new ApiException(error)
            }
    },


    async removeCoupon({auth,dynamicState,data}) {

        const path = `/v1/offers/remove_coupon?code_id=${data.code_id}
                      &offer_id=${data.offer_id}&request_id=${data.request_id}`;
        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
       
        const options = {"dynamicState":dynamicState,"reformatData":false};
        
        try {

            const response = await ApiService.get(path,headers,{},options);
            const request = response.data; 

            return request
        
            } catch(error) {
                throw new ApiException(error)
            }
    },

    async createPayment({auth,dynamicState,data}){

        const path = '/v4/stripe/load_or_create_payment_intent';
        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
        const options = {"dynamicState":dynamicState,"reformatData":true};
       
        try {

            const response = await ApiService.post(path,headers,data,options);
            const request = response.data; 

            return request
        
            } catch(error) {
                throw new ApiException(error)
            }

    },


    async getSingleRequest({auth,dynamicState,req_id}) {
     
        const path = '/v4/request/'+req_id;
        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
       
        const options = {"dynamicState":dynamicState,"reformatData":false};
        const data = {};
 
        try {

            const response = await ApiService.get(path,headers,data,options);
            const request = response.data; 

            return request
        
            } catch(error) {
                throw new ApiException(error)
            }
      
    },


    async updateSimilarRequest({auth,dynamicState,req_id,value}) {
     
        const path = '/v4/requests/'+req_id+'/starred/'+value;
        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
       
        const options = {"dynamicState":dynamicState,"reformatData":false};
        const data = {};
 
        try {

            const response = await ApiService.patch(path,headers,data,options);
            const request = response.data; 

            return request
        
            } catch(error) {
                throw new ApiException(error)
            }
      
    },

    async getRequestUnRated({auth,dynamicState}) {
     
        const path = '/v4/requests/unrated';
        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
        
        const options = {"dynamicState":dynamicState,"reformatData":false};
        const data = {};
 
        try {
            const response = await ApiService.get(path,headers,data,options);
            const request = response.data; 

            return request
        
            } catch(error) {
                throw new ApiException(error)
            }
      
    },
    async requestRating({auth,dynamicState,data}) {

        const path = '/v1/ratings';
        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
        const options = {"dynamicState":dynamicState,"reformatData":true};
       
        try {

            const response = await ApiService.put(path,headers,data,options);
            const request = response.data; 

            return request
        
        } catch(error) {
            throw new ApiException(error)
        }
      
    }



};
export default RequestService;