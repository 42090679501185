import { Box } from '@mui/material'
import Typography from '@mui/material/Typography';
import PaymentMethodBox from '../../components/box/PaymentMethodBox';
import { useSelector } from 'react-redux';
import { setPaymentMethodId } from '../../services/request/requestSlice';
import Translation from '../../utils/Translation';


export default function DeliveryLocations() {

  const {paymentMethodId} = useSelector(state=> state.request);
  
  return (
     <Box sx={{ 
      paddingBottom:'3%',
      paddingLeft:'2%'}}>

          <Typography variant="h6" component="h6" className='font-weight-600 mb-1 mt-2'>
             <Translation data="request.payment_title"  />
          </Typography>

          <Box className='all-payment'>

            <PaymentMethodBox value={paymentMethodId} action={setPaymentMethodId } />

          </Box>
     </Box>
  )
}
