import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import {Link} from '@mui/material';
import {logoutRequest} from '../../services/auth/authSlice';
import { useDispatch } from 'react-redux';

const PageDriver  = () => {

  const dispatch = useDispatch();

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
      }}
    >
      <Typography variant="h3" component="h3" sx={{ fontSize: '3rem', fontWeight: 'bold' }}>
      This App is For Customers Only<br></br>Drivers not Permitted to use it.
      </Typography>
      <Typography variant="h6" component="p" sx={{ mb: 2 }}>
         Can you download application from below
      </Typography>

        <Link href="https://deliverit.ae/#header02-f" target="_blank" 
            sx={{
                fontSize:'1.5rem',
                color:"#0db2ad",
                textDecoration:"none",
            }}
        > 
           Got To Download
        </Link>

        <Button sx={{mt:"1rem",background:"#0db9ca",'&:hover': {
              background: "#0db9ca",
        } }} variant="contained" color="primary"  onClick={() => dispatch(logoutRequest())}>
                logout
            </Button>
     
     
    </Container>
  );
};

export default PageDriver;