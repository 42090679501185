export class ApiException extends Error {
    constructor({ name,errorCode,message }) {
      super();
      this.name = name;
      this.errorCode = errorCode;
      this.message = message;
    }

    getError(){
        return {
          "name" : this.name,
          "message":this.message,
          "errorCode":this.errorCode
        }
    }
  }
  