import { useState,useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import {updateProfileAsync, sendVerifyEmailAsync,setVerifyEmail, setErrorProfile} from '../../services/profile/profileSlice'
import { useDispatch,useSelector } from 'react-redux';
import ResultSend from '../../components/alert/ResultSend';
import Stack from '@mui/material/Stack';
import {  getDownloadURL, ref as storageRef,  uploadBytes } from 'firebase/storage';
import {storage} from '../../config/firebase-config';
import Translation from '../../utils/Translation';

export default function Profile() {
  const auth = useSelector(state => state.auth);
  const dynamicState = useSelector(state => state.dynamicConfig);
  const {userProfile,errorProfile,statusUpdateProfile,statusVerifyEmail,verifyEmail} = useSelector(state => state.profile);
  const [nickName,setNickname] = useState(userProfile?.nickname? userProfile?.nickname:'');
  const [email,setEmail] = useState(userProfile?.email? userProfile?.email:'');
  const [msg,setMsg]= useState(null);
  const [update,setUpdate]= useState(false);
  const dispatch = useDispatch();
  const handleMsg=(value)=> setMsg(value);
  const [image, setImage]  = useState(null);
  

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleButton =  () =>{

    if(email && nickName)
      return false;

    return true;
  }

  const handleUpload = () => {
    
    const imageRef = storageRef(storage, `user/${auth.user.uid}`);

     uploadBytes(imageRef, image)
      .then((snapshot) => {
        getDownloadURL(snapshot.ref)
          .then((imgUrl) => {
            sendProfile(imgUrl);
          })
          .catch((error) => {
            console.log(error)
          });
      })
      .catch((error) => {
        console.log(error)
      });
  
  };

  const handleSendProfile = (e) => {
    e.preventDefault();

    if(validateEmail(email)){
      dispatch(setErrorProfile(null));
      if(image)
        handleUpload(); // upload image
      else
        sendProfile(userProfile?.profile_image_url);

    }else{
      dispatch(setErrorProfile("email not valid"));
      handleMsg("email not valid");
    }


    
  }

  const sendProfile = (profile_url) => {
    dispatch(updateProfileAsync({auth,dynamicState,nickName,email,profile_url}));
    handleMsg("");
    setUpdate(true);
  } 

  const handleSendVerifyEmail = () =>{
    
    if(verifyEmail)
      return;

    dispatch(sendVerifyEmailAsync({auth,dynamicState}));
  }

  useEffect(()=>{

    if(statusVerifyEmail === "succeeded") {
      // for prevent resend verify email
      dispatch(setVerifyEmail(true));
    }
     
   
    if(statusVerifyEmail === "failed")
      handleMsg(errorProfile)

  },[statusVerifyEmail,dispatch]);


  useEffect(()=>{
  
    if(statusUpdateProfile === "succeeded") {
      (update) && handleMsg(<Translation data="profile.msg"  />);
      dispatch(setVerifyEmail(userProfile?.email_verified === 0? false :true));
    }

    if(statusUpdateProfile === "failed")
      (update) && handleMsg(errorProfile);
    
    
  },[statusUpdateProfile,dispatch]);

  return (
    <Box sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 5
      }}>

    <Box sx={{margin:"10px"}}>
        <Avatar className='profile-avatar'  src={(userProfile?.profile_image_url)?
        userProfile.profile_image_url:require('../../assets/image/profile/avatar.jpg')} />
    </Box>
  
    <Typography component="h1" variant="b" sx={{fontSize:"30px"}}>
      {(userProfile?.nickname)?userProfile.nickname:"User Profile"}
    </Typography>

    <Box component="form"  noValidate sx={{ mt: 1,width:"50%" }} className='input-profile' onSubmit={(e) =>handleSendProfile(e)} >

      <TextField
        id="outlined-read-only-input"
        label=<Translation  data="profile.mobile_number"  />
        defaultValue={localStorage.getItem("phoneNumber")}
        InputProps={{
          readOnly: true,
        }}
        fullWidth
        />

      <TextField
        id="outlined-required"
        label=<Translation  data="profile.nickName"  />
        type='text'
        value={nickName}
        onChange={(e) => setNickname(e.target.value)}
        fullWidth
        />

        <TextField
            required
            id="outlined-required"
            label=<Translation  data="profile.email"  />
            type='email'
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            fullWidth
          />  

          {(userProfile?.email === email) && (userProfile?.email_verified === 0) &&
          <Stack direction="row" spacing={2} sx={{mb:'1rem'}}>
          {
            verifyEmail ? 
             <Button variant="outlined" className='button-verify'  disabled>
               <Translation  data="profile.verify_email"  /> </Button> 
             :
             <Button variant="outlined" className='button-verify'  onClick={()=>handleSendVerifyEmail()}>
             <Translation  data="profile.verify_email"  /> </Button>
          }
          </Stack>
          }
     
       
      <Box sx={{display:"block"}}>
        <TextField sx={{width:"100%"}} id="outlined-basic"  variant="outlined" type='file' onChange={handleChange}  />
      </Box>

      {(msg) &&  <ResultSend className="mt-1" msg={msg}  error={errorProfile?true:false} />}

      <Button
        type="submit"
        fullWidth
        variant="contained"
        disabled={handleButton()}
        sx={{ mt: 3, mb: 2 }}>
        <Translation  data="profile.update"  /> 
      </Button>

    </Box>

  </Box>
  );
}







