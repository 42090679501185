
import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import AddressService from "./address_service";
import { act } from "react";

const initialState = {
    isLoading:null,
    data: null,
    addressData:null,
    errorAddress: null,
    status: 'idle',
    addressIsDeleted:null,
    addressIsUpdated:null,
    findErrorAddress:false,
    checkAddressInUae:-1  // -1 not selected , 1 true, 0 false
};

// Async action creator
export const  loadAddressAsync = createAsyncThunk("type/loadAddressAsync", async ({auth,dynamicState},{rejectWithValue}) => {

  try{

    const address = await AddressService.loadAddress({auth,dynamicState}); // Assuming loadAddress is an async function

    return address;

  } catch (error){
    return rejectWithValue({error:error.getError().message})
  }
});

// Async action createAddressAsync
export const  createAddressAsync = createAsyncThunk("type/createAddressAsync", async ({auth,dynamicState,data},{rejectWithValue,dispatch}) => {

  try{

    const address = await AddressService.createAddress({auth,dynamicState,data}); // Assuming createAddress is an async function
    dispatch(setDataAddress({data}))
    return address;

  } catch (error){
    return rejectWithValue({error:error.getError().message})
  }
});


// Async action updateAddressAsync
export const  updateAddressAsync = createAsyncThunk("type/updateAddressAsync", async ({auth,dynamicState,data,addressId},{rejectWithValue,dispatch}) => {


  try{
    const address = await AddressService.updateAddress({auth,dynamicState,data,addressId}); // Assuming createAddress is an async function
    dispatch(setIdUpdated(addressId));
    dispatch(setDataAddress({data}))
  
    return address;

  } catch (error){
    return rejectWithValue({error:error.getError().message})
  }
});


// Async action deleteAddressAsync
export const  checkAddressInUaeAsync = createAsyncThunk("type/checkAddressInUaeAsync", async ({auth,dynamicState,coordinates},{rejectWithValue}) => {

  try{
    const address = await AddressService.checkAddressInUae({auth,dynamicState,coordinates}); // Assuming createAddress is an async function

    return address;

  } catch (error){
    return rejectWithValue({error:error.getError().message})
  }
});


export const  deleteAddressAsync = createAsyncThunk("type/deleteAddressAsync", async ({auth,dynamicState,data},{rejectWithValue,dispatch}) => {

  try{
    const address = await AddressService.deleteAddress({auth,dynamicState,data}); // Assuming createAddress is an async function
    dispatch(setIdDeleted(data.fav_id));

    return address;

  } catch (error){
    return rejectWithValue({error:error.getError().message})
  }
});


const addressSlice = createSlice({
    name: 'address',
    initialState,
    reducers: {
        setStatusAddress: (state, action) => {
          state.status = action.payload.status;
        },setDataAddress: (state,action) => {
          state.addressData = action.payload.data;
        },setIdDeleted : (state,action) => {
          state.addressIsDeleted = action.payload;
        },setIdUpdated : (state,action) => {
          state.addressIsUpdated = action.payload;
        },setFindErrorAddress: (state,action) => {
          state.findErrorAddress = action.payload;
        },setCheckAddressInUae: (state,action) => {
          state.checkAddressInUae = action.payload;
        }
    },
    extraReducers: builder => {
        builder
          .addCase(loadAddressAsync.pending, (state, action) => {
            state.status = 'loading';
            state.isLoading = true;
            state.data = [];
            state.findErrorAddress = false;
          })
          .addCase(loadAddressAsync.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.isLoading = false;
            state.errorAddress = null;
            state.data = action.payload;  
          })
          .addCase(loadAddressAsync.rejected, (state, action) => {
            state.status = 'failed';
            state.isLoading = false;
            state.errorAddress = action.payload.error;
            state.findErrorAddress = true;
            state.data = [];
            console.log(action.payload.error)
          
          })
          .addCase(createAddressAsync.pending, (state, action) => {
            state.status = 'loading';
            state.errorAddress = null;
          })
          .addCase(createAddressAsync.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.errorAddress = null;
            state.data = [{"fav_id":action.payload.fav_id,"address_data": state.addressData},...state.data];
          })
          .addCase(createAddressAsync.rejected, (state, action) => {
            state.status = 'failed';
            state.errorAddress = action.payload.error;
            console.log(action.payload.error)
          })
          .addCase(updateAddressAsync.pending, (state, action) => {
            state.status = 'loading';
            state.errorAddress = null;
          })
          .addCase(updateAddressAsync.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.errorAddress = null;
            state.data = state.data.filter((val) => val.fav_id !== state.addressIsUpdated);
            state.data = [{"fav_id":state.addressIsUpdated,"address_data":state.addressData},...state.data];
          })
          .addCase(updateAddressAsync.rejected, (state, action) => {
            state.status = 'failed';
            state.errorAddress = action.payload.error;
            console.log(action.payload.error)
          })
          .addCase(deleteAddressAsync.pending, (state, action) => {
            state.status = 'loading';
            state.errorAddress = null;
          })
          .addCase(deleteAddressAsync.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.errorAddress = null;
            state.data = state.data.filter((val) => val.fav_id !== state.addressIsDeleted);
          })
          .addCase(deleteAddressAsync.rejected, (state, action) => {
            state.status = 'failed';
            state.errorAddress = action.payload.error;
            console.log(action.payload.error)
          })
          .addCase(checkAddressInUaeAsync.pending, (state, action) => {
            state.checkAddressInUae = -1;
          })
          .addCase(checkAddressInUaeAsync.fulfilled, (state, action) => {
            state.checkAddressInUae = 1;
          })
          .addCase(checkAddressInUaeAsync.rejected, (state, action) => {
            state.checkAddressInUae = 0;
          })
      }
});


export const {setStatusAddress,setDataAddress,setIdDeleted,setIdUpdated,setFindErrorAddress,setCheckAddressInUae} = addressSlice.actions;
export default addressSlice.reducer;