import SelectAddress from '../../components/box/SelectAddress'
import { Box } from '@mui/material'
import Typography from '@mui/material/Typography';
import {useEffect, useState} from 'react'
import { CircularProgress } from '@mui/material';
import { useSelector,useDispatch } from 'react-redux';
import AddSection from '../address/AddSection';
import { setPickupAddressId,setDropAddressId,setAddressPickup,setAddressDrop, setDeliveryDate,setDeliveryTime } from '../../services/request/requestSlice';
import CalculateTimePath from '../../components/map/CalculateTimePath';
import {APIProvider} from '@vis.gl/react-google-maps';
import { googleMapsApiKey } from '../../utils/helpers';
import Translation from '../../utils/Translation';

export default function DeliveryLocations() {

  const {isLoading}= useSelector(state => state.address)
  const dispatch = useDispatch();
  const {pickupAddressId,dropAddressId} = useSelector(state => state.request);
  const address = useSelector(state => state.address);
  const [selectAddress,setSelectAddress] = useState(false);
  let addressValPickup = null;
  let addressValDrop = null;
  
  useEffect(()=>{

    if(pickupAddressId){
      addressValPickup = address.data.filter((val) => val.fav_id === pickupAddressId);
      dispatch(setAddressPickup(addressValPickup));        
    }
   
    if(dropAddressId){
      addressValDrop = address.data.filter((val) => val.fav_id === dropAddressId);
      dispatch(setAddressDrop(addressValDrop))
    }
      
    //reset date and time
    setSelectAddress(false);
    dispatch(setDeliveryDate(''));
    dispatch(setDeliveryTime('')); 

    if(pickupAddressId && dropAddressId)
      setSelectAddress(true);
    
  },[pickupAddressId,dropAddressId]);


  return (
     <Box sx={{ 
      paddingTop:'3%',
      paddingBottom:'3%',
      paddingLeft:'2%'}}>

          <AddSection   />

          <Typography variant="h6" component="h6" className='font-weight-600 mb-1 mt-2'>
             <Translation data="request.pickup_address"   />
          </Typography>
          {isLoading === true ?   <CircularProgress className='full-preloader-position' />  :
            <Box className='all-address'>

            <SelectAddress destination={dropAddressId} value={pickupAddressId} action={setPickupAddressId} />

            </Box>
          }

          <Typography variant="h6" component="h6" className='font-weight-600 mb-1 mt-2'>
            <Translation data="request.drop_address"   />
          </Typography>
          {isLoading === true ?   <CircularProgress className='full-preloader-position' />  :
            <Box className='all-address'>

            <SelectAddress destination={pickupAddressId} value={dropAddressId} action={setDropAddressId} />

            </Box>
          }

          <APIProvider apiKey={googleMapsApiKey()}>
          </APIProvider>

          {
            ((selectAddress)) && 
              <>
              <Box sx={{paddingTop:'3%'}}>

                <CalculateTimePath />
    
              </Box>
              </>
          }
     </Box>
  )
}
