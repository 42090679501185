import React from 'react'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
export default function ResultSend(props) {
 
  return (
    (props.msg) &&
        <Stack sx={{ width: '100%' }}>
          {props.error ?
            <Alert  severity="error">{props.msg}</Alert>:
            <Alert  severity="success">{props.msg}</Alert>
          }
        </Stack>
      
  );
}
