import React, { useEffect } from 'react'
import BoxRequest from '../../components/box/BoxRequest';
import TableHome from '../../components/table/TableRequest';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { getStatuesForAllRequest, setReqToShow,setSimilar } from '../../services/request/requestSlice';
import { Typography } from '@mui/material';
import ReviewRequestCard from '../../components/card/ReviewRequestCard';
import Translation from '../../utils/Translation';
import RefreshButton from '../../components/button/RefreshRequestButton';


export default function Request() {
    
    const request = useSelector(state => state.request);
    const dispatch = useDispatch();

    const requestSimilar = {"label":<Translation data="home.similar" />,"name":"Similar","status":8};
    const requestType=[
        {"label":<Translation data="home.archive" />,"name":"Archive","status":7},
        {"label":<Translation data="home.waiting_driver" />,"name":"Waiting For Driver","status":0,"counter":String(request.waitingForDriver)},
        {"label":<Translation data="home.has_offer" />,"name":"Has Offer","status":1,"counter":String(request.hasOffer)},
        {"label":<Translation data="home.in_progress" />,"name":"In Progress","status":2,"counter":String(request.inProgress)}
    ];


    const getRequest = (status,similar) => { 
      dispatch(setSimilar(similar));
      dispatch(setReqToShow({reqToShow:status}));
    }
    

    useEffect(()=>{
        dispatch(getStatuesForAllRequest())
    },[request.data])




    return (
        <>
        {request.requestUnRated.length > 0 &&
         <Box>
            <Typography variant="h5" component="h5" className='font-weight-600 mb-1 request-title'>
                <Translation data="home.review_request" />
            </Typography>

            <Box className="review-request">
               <ReviewRequestCard  />
            </Box>
        </Box>
        }
        
        {request.reqToShow !==null ? <TableHome status={request.reqToShow}  /> : 
        <>
        <Box>
            <Typography variant="h5" component="h5" className='font-weight-600 mb-1 request-title'>
                <Translation data="home.similar_request"  />
            </Typography>

            <Box  className='all-request'>
                <BoxRequest action={getRequest}  val={requestSimilar} />
            </Box>
        </Box>
       
        <Box>
           <Box className="display-inline-flex">
              <Typography variant="h5" component="h5" className='font-weight-600 mb-1 request-title'>
                <Translation data="home.all_requests"  />
              </Typography>
              <RefreshButton  />
           </Box>
           
            <Box  className='all-request'>
            
                {requestType.map((value,idx)=> {
                    return <BoxRequest action={getRequest} key={idx}  val={value} />
                })}
            
            </Box>
        </Box>
       
        </>
        }
        </>
    )
}
