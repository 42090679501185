import ApiService from '../api/api_service';
import {ApiException} from '../../utils/exceptions';

const ProfileService = {

    async loadProfile({auth,dynamicState}) {
     
        const path = '/v4/profile';
        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
        const data = {}
        const options = {"dynamicState":dynamicState,"reformatData":true}

      try {

        const response = await ApiService.get(path,headers,data,options);
        const profiles = response.data; 
        return (profiles.length === 0) ? profiles.length : profiles[0]
       
        } catch(error) {
            throw new ApiException(error)
        }
        
     
    },

    async updateProfile({auth,dynamicState,nickName,email,profile_url}) {  

        const path = '/v1/profiles';
        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
        const data = { 
                nickname: nickName,
                email: email,
                mobile_number: localStorage.getItem("phoneNumber"),
                profile_image_url: profile_url,
                user_type: 0,
                request_filters: null,
                is_seller_user: "false",
                tenant_id: 1
            }

    
        const options = {"dynamicState":dynamicState,"reformatData":true}

        try{
            const response = await ApiService.put(path,headers,data,options);
            return response;
        }catch(error){
            throw new ApiException(error)
        }  
    },

    async sendVerifyEmail({auth,dynamicState}){

        const path = '/v1/profiles/send-email-verification';
        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
        const data = { }
        const options = {"dynamicState":dynamicState,"reformatData":false}

        try{
            const response = await ApiService.post(path,headers,data,options);
            return response;
        }catch(error){
            throw new ApiException(error)
        }  
    },

    async updateFcmToken ({auth,dynamicState,token}){

        const path = '/v1/profiles/fcm/update';
        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
        const data = { 
                fcm_token: token
        }
        const options = {"dynamicState":dynamicState,"reformatData":true}

        try{
            const response = await ApiService.put(path,headers,data,options);
            return response;
        }catch(error){
            throw new ApiException(error)
        }  

    }

};
export default ProfileService;