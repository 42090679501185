
import { getLanguage } from '../../utils/helpers';
import ChatList from '../../components/chat/ChatList';
import { Box, Drawer } from '@mui/material';
import SingleChat from '../../components/chat/SingleChat';
import { setIsLoadingChat } from '../../services/chat/chatSlice';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setWidget,setChatSingle } from '../../services/chat/chatSlice';
import { getSingleRequestAsync } from '../../services/request/requestSlice';
import { useEffect } from 'react';

const Sidebar = ({ open , toggleDrawer  }) => {

    const dispatch = useDispatch();
    const {widget} = useSelector(state => state.chat);
    const {statusSingleRequest} = useSelector(state => state.request);
    const auth = useSelector(state => state.auth);
    const dynamicState = useSelector(state => state.dynamicConfig);
   
    const changeWidget = (value) =>{
      dispatch(setIsLoadingChat(true));
      dispatch(setWidget(value));
  }

    const handleChat = (widget,obj) =>{
      
      if(widget === "single-chat"){

        dispatch(setChatSingle(obj));

        // check request
        let req_id = obj.req_id 
        dispatch(getSingleRequestAsync({auth,dynamicState,req_id}));
        // end check 
      } else {
        changeWidget(widget);
      }
    
    }

    useEffect(()=>{
     
      if(statusSingleRequest === "succeeded"){
        
        changeWidget("single-chat");
      
      }
    
    },[dispatch,statusSingleRequest]);
    
  
    return (
      <Drawer anchor={getLanguage() === "ar"?"right":"left"} open={open} onClose={toggleDrawer(false)} className='chat-sidebar'>
       <Box role="presentation">
        {widget === "list"? 
            <ChatList handleChat = {handleChat} changeWidget={changeWidget}  />:
            <SingleChat changeWidget = {changeWidget} />}
      </Box>
      </Drawer>
    );
  };
export default Sidebar
  