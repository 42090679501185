import * as React from 'react';
import { useState,useEffect,useRef }  from "react"
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import HomeIcon from '@mui/icons-material/Home';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ContactsIcon from '@mui/icons-material/Contacts';
import SettingsIcon from '@mui/icons-material/Settings';
import {Link,useNavigate} from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {logoutRequest} from '../../services/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import PublicIcon from '@mui/icons-material/Public';
import {setUserProfile} from '../../services/profile/profileSlice';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import SidebarOption from './SidebarOption';
import { setValueTab,setRoute } from '../../services/globalSlice';
import { loadStyle,keyLang } from '../../utils/helpers';
import Translation from '../../utils/Translation'
import CheckNotification from '../../components/notification/CheckNotification';
import ProfileService from '../../services/profile/profile_service';


const settings = ["Profile","Logout"];
const links= {"/":0,"/add_request":1,"/address":2,"/setting":4}
const langs = ['English',"Arabic"];

function Navbar() {
  
  const dispatch = useDispatch();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { statusLogout,user} = useSelector(state => state.auth);
  let location = useLocation(); 
  const [language, setLanguage] = useState(localStorage.getItem("i18nextLng"));
  const { t, i18n } = useTranslation();
  const {userProfile} = useSelector(state => state.profile);
  const {route,valueTab} = useSelector(state => state.global);
  const auth = useSelector(state=>state.auth);
  const dynamicState = useSelector(state => state.dynamicConfig);

  const handleLanguage = (event, index) => {

    let lang = keyLang[index];
    i18n.changeLanguage(lang);

    loadStyle(lang)

    setLanguage(lang);
    setOpen(false);
  };


  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {

    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleChange = (event, newValue) => {
      dispatch(setValueTab(newValue));
  }

  const handleRoute=(newRoute)=>{
    dispatch(setRoute(newRoute));   // set new route
    navigate(newRoute); // set new page by route
  }

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const HandleUserLogoutClick = (setting) => {

    if(setting ===   "Logout"){
      let token = "0";
      ProfileService.updateFcmToken({auth,dynamicState,token});
      localStorage.clear();
      dispatch(logoutRequest());
    }

    if(setting === "Profile" ){
      handleChange(null,undefined);
      handleRoute('/profile');
    }
     
    handleCloseUserMenu()
  }

  useEffect(()=>{
    
    handleChange(null,links[location.pathname])
    handleRoute(location.pathname);   // set route base on page

  },[]);

  // check if user find to redirect
  useEffect(()=>{
  
    if(!user){
      dispatch(setUserProfile({userProfile:null}));
      navigate('/login');
    }
    
  },[statusLogout,dispatch]);
  


  return (
    <Box sx={{ flexGrow: 5 }} className='navbar-background'>
       <Container className='no-padding'>
            <Grid container spacing={3} >

              <Grid  container lg={2} md={2} sm={2}    className="responsive-logo"  >

                <Grid className="margin-auto padding-main-logo" onClick={(e) =>handleChange(e,0)}>
                  <Link onClick={()=>handleRoute('/')} to="/"><img src={require('../../assets/image/layout/logo.png')}  className='main-logo'  alt='' /></Link>
                </Grid>

              </Grid>

              <Grid lg={8} md={7} sm={8}  xs={10} >
                <Tabs value={valueTab} onChange={handleChange} 
                  className='space-around-tabs'
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  > 
                  <Tab icon={<HomeIcon />} label=<Translation data="layout.home"  /> className="color-navbar"   onClick={()=>(handleRoute('/'))}  />
                  <Tab icon={<AddBoxIcon />} label=<Translation data="layout.add_request"  /> className="color-navbar"    onClick={()=>(handleRoute('/add_request'))}  />
                  <Tab icon={<ContactsIcon />} label=<Translation data="layout.addresses"  /> className="color-navbar"    onClick={()=>(handleRoute('/address'))} />
                  <Tab icon={<SettingsIcon />} label=<Translation data="layout.setting"  /> className="color-navbar"    onClick={()=>(handleRoute('/setting'))} />
                </Tabs>
              </Grid>
              
              <Grid  lg={2} md={3}  sm={2}   className="desktop-option"  container>
              
                  <Grid className="margin-auto" lg={4} md={4} sm={4} xs={4}>
                    <CheckNotification  />
                  </Grid>

                  <Grid className="margin-auto" lg={4} md={4} sm={4} xs={4}>
                    <ButtonGroup
                      variant="contained"
                      ref={anchorRef}
                      className='box-shadow-unset'
                      aria-label="Button group with a nested menu">
                      <IconButton
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}>
                        <PublicIcon />
                      </IconButton>
                    </ButtonGroup>
                    <Popper
                        sx={{
                          zIndex: 1,
                        }}
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                  {langs.map((option, index) => (
                                    <MenuItem
                                      key={option}
                                      disabled={index === 2}
                                      selected={index === (language === keyLang[0]? 0 :1)}
                                      onClick={(event) => handleLanguage(event, index)}
                                    >
                                      {option}
                                    </MenuItem>
                                  ))}
                                </MenuList>
                              </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                    </Popper>
                  </Grid>

                  <Grid className="margin-auto padding-profile" lg={4} md={4} sm={4} xs={4}>
                    <Box sx={{ flexGrow: 0 }}>
                      <Tooltip title={<Translation data="layout.Profile"  />}>
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                          <Avatar  
                            src={(userProfile?.profile_image_url)?
                              userProfile.profile_image_url:require('../../assets/image/profile/avatar.jpg')}
                          />
                          
                        </IconButton>
                      </Tooltip>
                      <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                      >
                        {settings.map((setting) => (
                          <MenuItem key={setting} onClick={() => HandleUserLogoutClick(setting)}>
                               <Translation data={"layout."+setting}  />
                          </MenuItem>
                        ))}
                      </Menu>
                    </Box>
                  </Grid>
                
              </Grid>

              
              <Grid className="mobile-option margin-auto mobile-button" sm={1}  xs={2}>
                  <SidebarOption 
                  handleRoute={handleRoute} 
                  handleChange={handleChange} 
                  handleLanguage={handleLanguage}
                  HandleUserLogoutClick={HandleUserLogoutClick}
                  langs={langs}
                   />
              </Grid> 

             </Grid>
       </Container>

     
    </Box>
  );
}

export default memo(Navbar);


