import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getRemoteConfig } from "firebase/remote-config";
import { getStorage } from "firebase/storage";
import { getFirestore } from 'firebase/firestore';
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyA5qj88Gbv3jCtM6V5NoPDHwo13Xu95OS4",
  authDomain: "deliverit-ecf99.firebaseapp.com",
  databaseURL: "https://deliverit-ecf99.firebaseio.com",
  projectId: "deliverit-ecf99",
  storageBucket: "deliverit-ecf99.appspot.com",
  messagingSenderId: "181274403521",
  appId: "1:181274403521:web:0fa47066d3ef531fec2a40",
  measurementId: "G-GW17ZDJF32"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const remoteConfig = getRemoteConfig(app);
const storage = getStorage(app);

// Initialize Firestore and get a reference to the service
const db = getFirestore(app);

// Messaging service
const messaging = getMessaging(app);


export {auth,remoteConfig,storage,db,messaging};