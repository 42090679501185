import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoadingChat:true,
    chatList:[],
    widget:'list',
    chatSingle:{},
    readOnly:false,
    findErrorChat:false,
    errorChat:null,
    badgeContent:0,
    isOpen:false
}

const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        setIsLoadingChat: (state, action) => {
            state.isLoadingChat = action.payload;
        },setChatList:(state,action) => {
            state.chatList = action.payload;
        },setChatSingle : (state, action) => {
            state.chatSingle = action.payload;
        },setWidget: (state, action) => {
            state.widget = action.payload;
        },setReadOnly : (state, action) => {
            state.readOnly = action.payload;
        },setFindErrorChat: (state, action) => {
            state.findErrorChat = action.payload;
        },setErrorChat: (state, action) => {
            state.errorChat = action.payload;
        },setBadgeContentChat: (state, action) => {
            state.badgeContent= ((action.payload === 1 && state.widget === "list") 
                || (action.payload === 1 && state.isOpen === false) ) ?
                  action.payload + state.badgeContent : 0
        },setIsOpen: (state, action) => {
            state.isOpen = action.payload
        }
    }
});

export const { setIsLoadingChat,setChatList,setChatSingle,setWidget,
    setReadOnly,setFindErrorChat,setErrorChat,setBadgeContentChat,setIsOpen } = chatSlice.actions;

export default chatSlice.reducer;