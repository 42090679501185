import React from 'react'
import { Box, Typography } from '@mui/material'
import Button from '@mui/material/Button';
import ArchiveIcon from '@mui/icons-material/Archive';
import { useSelector } from 'react-redux';
import { setReadOnly } from '../../services/chat/chatSlice';
import { useDispatch } from 'react-redux';
import Translation from '../../utils/Translation';

export default function HeaderChat({type,changeWidget,name}) {

  const {readOnly} = useSelector(state => state.chat);
  const dispatch = useDispatch();

  return (
    <header>
    {type === "single-chat" ?  
    <>
      <Box>
          <Typography variant='h6' component='h4'>
           <Translation data="chat.driver"  />  {name}
          </Typography>
      </Box>
    
      <button onClick={() => changeWidget("list")} className="back" > <Translation data="chat.back"  /> </button>
    </>
      : 
      <>
      <Typography variant='h5' component='center'>
        {readOnly?  <Translation data="chat.read_only"  />  : <Translation data="chat.active_chat"  />} 
      </Typography>
        <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        endIcon={<ArchiveIcon />}
        onClick={()=>dispatch(setReadOnly(!readOnly))}
        >
          {readOnly? <Translation data="chat.Unarchive"  />:<Translation data="chat.archive"  />} 
      </Button>
      </> 
      }

    </header>
  )
}


