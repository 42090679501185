import React from 'react'
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button } from '@mui/material';
import { loadAddressAsync } from '../../services/address/addressSlice';
import { useDispatch,useSelector } from 'react-redux';

export default function RefreshAddressButton() {

 const dispatch = useDispatch();
 const auth = useSelector(state => state.auth);
 const dynamicState = useSelector(state => state.dynamicConfig);

  return (
    <Button className='refresh-button' onClick={()=> dispatch(loadAddressAsync({auth,dynamicState}))}>
       <RefreshIcon />
    </Button>
  )
}
