import { configureStore } from '@reduxjs/toolkit';
import  authReducer  from '../services/auth/authSlice';
import profileReducer from '../services/profile/profileSlice';
import globalReducer from '../services/globalSlice.js';
import dynamicConfigReducer from '../services/dynamic_config/dynamicConfigSlice';
import requestSLice from '../services/request/requestSlice';
import addressSlice from '../services/address/addressSlice.jsx';
import chatSlice from '../services/chat/chatSlice.js';


export default configureStore({
      reducer: {
        global: globalReducer,
        auth: authReducer,
        dynamicConfig: dynamicConfigReducer,
        profile: profileReducer,
        request: requestSLice,
        address: addressSlice,
        chat: chatSlice
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
})

