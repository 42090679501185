import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    error: null,
    message: null,

    valueTab:0,
    route:'/',

    pageNotFound:false,

    isOnline:true,

    notification:[],
    badgeContent:0
}

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload.isLoading;
        },
        setError: (state, action) => {
            state.error = action.payload.error;
        },
        setMessage: (state, action) => {
            state.message = action.payload.message;
        },
        setPageNotFound: (state, action) => {
            state.pageNotFound = action.payload;

        },setValueTab : (state, action) => {

            if(action.payload !== undefined)
                state.valueTab = action.payload;
            else
                state.valueTab = 3;// set setting when select the navbar

        },setRoute: (state, action) => {
            state.route = action.payload;
        },setIsOnline : (state, action) => {
            state.isOnline = action.payload;
        },setNotification : (state, action) => {
            state.notification = [action.payload,...state.notification];
        },setBadgeContent: (state, action) => {
            state.badgeContent= (action.payload !== 0) ?  action.payload  : 0;
        }
    }
});

export const { setLoading, setError, setMessage, setPageNotFound,setValueTab,
    setRoute,setIsOnline, setNotification,setBadgeContent } = globalSlice.actions;

export default globalSlice.reducer;