

import * as React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useSelector,useDispatch } from 'react-redux';
import {setDriverId, setInstructions, setSpecialCare } from '../../services/request/requestSlice';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import SpcialCareLabel from '../../components/label/SpcialCareLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { useState,useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import RequestImg from '../../components/image/RequestImg';
import Translation from '../../utils/Translation';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function Details({handleChange}) {

  const dispatch = useDispatch();
  const {instructions,specialCare,imgUrl,driverId} = useSelector(state => state.request);
  const [refrigeration,setRefrigeration] = useState(specialCare.indexOf('0') > -1 ? true:false);
  const [fragile,setFragile] = useState(specialCare.indexOf('1') > -1 ? true:false);
  const [selectDriver,setSelectDriver] = useState(false);

  useEffect(()=>{
    
   let value = "";

   value = (refrigeration && !fragile) ?  "0":value ;
   value = (!refrigeration && fragile) ?  "1":value;
   value = (refrigeration && fragile) ? "0|1":value;

   dispatch(setSpecialCare(value));

  },[refrigeration,fragile]);

  return (
     <Box
        sx={{
        width: 500,
        maxWidth: '100%',
        paddingBottom:'3%',
        paddingLeft:'2%'
      }}
      className="icon-arabic"
      >

    <FormGroup>
       <FormLabel variant="h6" component="h6" className='font-weight-600 mb-1 mt-2'>
         <Translation data="request.special_care"  />
      </FormLabel>

      <FormControlLabel control={<Checkbox  />} label={<SpcialCareLabel  title={<Translation data="request.require_refrigeration" />} value="0" /> } 
        checked={refrigeration} onChange={(e) => setRefrigeration(e.target.checked)}  />


      <FormControlLabel control={<Checkbox />}  label={<SpcialCareLabel  title={<Translation data="request.fragile" />}  value="1"  />}
       checked={fragile}   onChange={(e) => setFragile(e.target.checked)}   />

    </FormGroup>
     
    <TextField value={instructions} onChange={(e)=>dispatch(setInstructions(e.target.value))} id="outlined-basic" fullWidth label={<Translation data="request.instruction" />}  variant="outlined" className='mb-1 mt-2' />

   
    {(!selectDriver && driverId ==="")  && 
    <Button component="label"  variant="contained" onClick={()=>setSelectDriver(true)}>
        <Translation data="request.select_driver"  />
    </Button>
    }

    {(selectDriver ||  driverId !=="") &&
      <TextField value={driverId} id="driverId"  
      onChange={(e)=>dispatch(setDriverId(e.target.value))} fullWidth 
      label={<Translation data="request.select_driver" />}  variant="outlined"  />
    }


    <FormGroup>

       <FormLabel variant="h6" component="h6" className='font-weight-600 mb-1 mt-2'>
       <Translation data="request.package_photo"  />
      </FormLabel>
                 
        {imgUrl && 
          <RequestImg  url={imgUrl}  />
        }
 

        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />} >
          <Translation data="request.upload_file"  />
          <VisuallyHiddenInput onChange={handleChange} type="file" />
        </Button>
    </FormGroup>

    </Box>
  )
}

