
import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import RequestService from "./request_service";
import { v1 as uuid } from 'uuid';
import { formatDate } from "../../utils/helpers";
import Translation from "../../utils/Translation";

const initialState = {
   status:"idle",
   isLoading:false,
   error:null,
   data:[],
   archive:[],
   newRequest:{},
   findErrorRequest:false,

   statusCancel:"idle",
   reqIdCancel:null, // for all single request put action
   reqToShow:null,  // 0,1,2,3,4,5,6,7,8,9
   similar:0,

   description:'',
   packageSize:'',

   pickupAddressId:null,
   dropAddressId:null,

   addressPickup:[],
   addressDrop:[],

   deliveryDate:'',
   deliveryTime:'',

   paymentMethodId:null,

   specialCare:'',
   instructions:'',
   imgUrl:null,
   driverId:'',

   activeStep : 0,
   skipped:new Set(),
   
   addedNewRequest:false,

   path:'' ,
   estimatedDuration:'',
   distance:'',

   statusOffer:"idle",
   offerObjects: null,
   offerSelected:null,
   paymentType:null,
  
   statusCoupon:"idle",
   coupon:false,
   newAmount:null,

   offerIdx:null,
   statusPayment:"idle",
   isLoadingPayment:false,

   statusSingleRequest:"idle",
   singleRequest:[],

   statusReview:"idle",
   requestUnRated : [],

   statusSingleRequestReview:"idle",
   singleRequestReview:[],


   waitingForDriver : 0,
   hasOffer : 0,
   inProgress : 0
};

// Async action creator
export const  createRequestAsync = createAsyncThunk("type/createRequestAsync", async ({auth,dynamicState,data},{rejectWithValue,dispatch}) => {

    try{
        const response = await RequestService.addRequest({auth,dynamicState,data});
        dispatch(setNewRequest(data));
        dispatch(resetValues());
        return response;
    
    } catch (error) {
        return rejectWithValue({error:error.getError().message})
    }

});

// Async action Get
export const  getRequestAsync = createAsyncThunk("type/getRequestAsync", async ({auth,dynamicState},{rejectWithValue}) => {

    try{
        const response = await RequestService.getRequest({auth,dynamicState});

        return response;

    }catch (error){
        return rejectWithValue({error:error.getError().message})
    }
});


export const cancelRequestAsync = createAsyncThunk("type/cancelRequestAsync", async ({auth,dynamicState,data},{rejectWithValue,dispatch}) => {

  try{
      const response = await RequestService.cancelRequest({auth,dynamicState,data});
      dispatch(setReqIdCancel({reqIdCancel:data.req_id}));

      return response;

  }catch (error){
      return rejectWithValue({error:error.getError().message})
  }
});


export const getArchiveRequestAsync = createAsyncThunk("type/getArchiveRequestAsync", async ({auth,dynamicState},{rejectWithValue}) => {

      try{
        const response = await RequestService.getArchiveRequest({auth,dynamicState});

        return response;

    }catch (error){
        return rejectWithValue({error:error.getError().message})
    }
});


export const getPathAndTimeEstAsync = createAsyncThunk("type/getPathAndTimeEstAsync", async ({pt1,pt2},{rejectWithValue}) => {

  try{
    const response = await RequestService.getPathAndTimeEst(pt1,pt2);

    return response;

}catch (error){
    return rejectWithValue({error:error.getError().message})
}

});


export const acceptOfferAsync = createAsyncThunk("type/acceptOfferAsync", async({auth,dynamicState,object},{rejectWithValue})=>{

  try{
    const response = await RequestService.acceptOffer({auth,dynamicState,object});

    return response;

  }catch (error){
      return rejectWithValue({error:error.getError().message})
  }

});


export const rejectedOfferAsync = createAsyncThunk("type/rejectedOfferAsync", async({auth,dynamicState,offer_id,object},{rejectWithValue})=>{

  try{
    const response = await RequestService.rejectOffer({auth,dynamicState,offer_id,object});

    return response;

  }catch (error){
      return rejectWithValue({error:error.getError().message})
  }

});


export const applyCouponAsync = createAsyncThunk("type/applyCouponAsync", async({auth,dynamicState,data},{rejectWithValue})=>{

  try{
    const response = await RequestService.applyCoupon({auth,dynamicState,data});

    return response;

  }catch (error){
      return rejectWithValue({error:error.getError().message})
  }

});


export const removeCouponAsync = createAsyncThunk("type/removeCouponAsync", async({auth,dynamicState,data},{rejectWithValue})=>{

  try{
    const response = await RequestService.removeCoupon({auth,dynamicState,data});

    return response;

  }catch (error){
      return rejectWithValue({error:error.getError().message})
  }
});


export const createPaymentAsync = createAsyncThunk("type/createPaymentAsync", async({auth,dynamicState,data},{rejectWithValue})=>{

  try{
    const response = await RequestService.createPayment({auth,dynamicState,data});

    return response;

  }catch (error){
      return rejectWithValue({error:error.getError().message})
  }

});


export const getSingleRequestAsync = createAsyncThunk("type/getSingleRequestAsync", async({auth,dynamicState,req_id},{rejectWithValue})=>{

  try{
    const response = await RequestService.getSingleRequest({auth,dynamicState,req_id});

    return response;

  }catch (error){
      return rejectWithValue({error:error.getError().message})
  }

});

export const getSingleRequestReviewAsync = createAsyncThunk("type/getSingleRequestReviewAsync", async({auth,dynamicState,req_id},{rejectWithValue})=>{

 
  try{
    const response = await RequestService.getSingleRequest({auth,dynamicState,req_id});
  
    return response;

  }catch (error){
 
      return rejectWithValue({error:error.getError().message})
  }

});


export const updateSimilarRequestAsync = createAsyncThunk("type/updateSimilarRequestAsync", async({auth,dynamicState,req_id,value},{rejectWithValue})=>{

  try{
    const response = await RequestService.updateSimilarRequest({auth,dynamicState,req_id,value});

    return response;

  }catch (error){
      return rejectWithValue({error:error.getError().message})
  }

});


// Async action Get
export const  getRequestUnRatedAsync = createAsyncThunk("type/getRequestUnRatedAsync", async ({auth,dynamicState},{rejectWithValue}) => {

  try{
      const response = await RequestService.getRequestUnRated({auth,dynamicState});
     
      return response;

  }catch (error){

      return rejectWithValue({error:error.getError().message})
  }
});


// Async action Put
export const  ratingAsync = createAsyncThunk("type/ratingAsync", async ({auth,dynamicState,data},{rejectWithValue}) => {

  try{
      const response = await RequestService.requestRating({auth,dynamicState,data});
     
      return response;

  }catch (error){

      return rejectWithValue({error:error.getError().message})
  }
});


export const UpdateRequestAfterNotifyAsync = createAsyncThunk("type/UpdateRequestAfterNotifyAsync", async({auth,dynamicState,req_id},{rejectWithValue})=>{
 
  try{
    const response = await RequestService.getSingleRequest({auth,dynamicState,req_id});
  
    return response;

  }catch (error){
 
      return rejectWithValue({error:error.getError().message})
  }

});

const requestSlice = createSlice({
    name: 'request',
    initialState,
    reducers: {
     setState:(state, action) => {
        state.status = action.payload.status
     },setReqIdCancel:(state,action) => { 
        state.reqIdCancel = action.payload.reqIdCancel
     },setRequests:(state,action) => {
      state.data = action.payload // for not select state in data
    },setReqToShow:(state,action) => {
       state.reqToShow=action.payload.reqToShow
     },setSimilar : (state,action) => {  // return here
      state.similar = action.payload;
     },setDescription:(state,action) => {
      state.description=action.payload
     },setPackageSize:(state,action) => {
      state.packageSize=action.payload
     },setPickupAddressId:(state,action) => {
      state.pickupAddressId=action.payload  
     },setDropAddressId:(state,action) => {
      state.dropAddressId=action.payload
     },setDeliveryDate:(state,action) => {
      
      if(action.payload > formatDate(new Date(),4))  //check maximum date
           state.deliveryDate= formatDate(new Date(),4);
      else if(action.payload < formatDate(new Date(),0))  //check minimum date
             state.deliveryDate= formatDate(new Date(),0);
      else
         state.deliveryDate=action.payload;
      
      state.deliveryTime = '';

     },setDeliveryTime:(state,action) => {
      state.deliveryTime=action.payload
     },setPaymentMethodId:(state,action) => {
      state.paymentMethodId=action.payload
     },setSpecialCare:(state,action) => {
      state.specialCare=action.payload
     },setInstructions:(state,action) => {
      state.instructions=action.payload
     },setActiveStep:(state,action) => {
      state.activeStep = action.payload;
     },setSkipped:(state,action) => {
      state.skipped = action.payload;
     },cloneRequest:(state,action) => {
      state.description= action.payload.description;
      state.packageSize= action.payload.package_size;
      state.addressPickup= action.payload.pickup_address_data;
      state.addressDrop=action.payload.drop_address_data;
      state.deliveryDate=action.payload.delivery_date;
      state.deliveryTime=action.payload.deilvery_window;
      state.paymentMethodId=action.payload.payment_method;
      state.specialCare=action.payload.special_care;
      state.instructions=action.payload.instructions;
      state.path = action.payload.path;
      state.imgUrl = action.payload.photo_url;
      state.estimatedDuration = action.payload.estimated_duration;
      state.activeStep= 4; // put before last step for update request
     },resetValues: (state,action)=>{
      state.status = "idle";
      state.description='';
      state.packageSize='';
      state.pickupAddressId=null;
      state.dropAddressId=null;
      state.deliveryDate='';
      state.deliveryTime='';
      state.paymentMethodId=null;
      state.specialCare='';
      state.instructions='';
      state.driverId='';
      state.addedNewRequest=false;
      state.imgUrl = null;
     },setAddressPickup :( state,action)  => {
      state.addressPickup = action.payload;
     },setAddressDrop : (state,action) => {
      state.addressDrop = action.payload;
     },setPath:(state,action) => {
      state.path = action.payload;
     },setEstimatedDuration:(state,action) => {
      state.estimatedDuration = action.payload;
     },setDistance : (state,action) => {
      state.distance = action.payload;
     },setOfferObjects : (state,action) => {
      state.offerObjects = action.payload;
     },setOfferSelected: (state,action) => {
      state.offerSelected = action.payload
     },setCoupon : (state,action) => {
      state.coupon = action.payload
     },setnewAmount : (state,action) => {
      state.newAmount = action.payload
     },setPaymentType : (state,action) => {
      state.paymentType = action.payload
     },setOfferIdx : (state,action) => {
      state.offerIdx =action.payload
     },setStatuesPayment : (state,action) => {
      state.statusPayment = action.payload
     },setFindErrorRequest: (state,action) => {
      state.findErrorRequest = action.payload
     },setImgUrl : (state,action) => {
      state.imgUrl = action.payload
     },setNewRequest: (state,action) => {
      action.payload.pickup_address_data = JSON.parse(action.payload.pickup_address_data);
      action.payload.drop_address_data = JSON.parse(action.payload.drop_address_data);
      state.newRequest = action.payload
     },setStatusSingleRequestReview : (state,action) => {
      state.statusSingleRequestReview = action.payload
     },getStatuesForAllRequest: (state,action) => {

      state.waitingForDriver=0;
      state.hasOffer=0;
      state.inProgress=0;
      
        state.data.forEach((val) => {
                
          if(val.status == 0){
            state.waitingForDriver = state.waitingForDriver + 1;
          }

          if(val.status == 1){
            state.hasOffer = state.hasOffer + 1;
          }

          if(val.status >= 2 && val.status <= 6){
            state.inProgress = state.inProgress + 1;
          }
          
        });

      },showNotify:(state,action) => {
       
        let singleRequest = state.data.filter((val) => ( val.req_id === action.payload.req_id)); 
        if(singleRequest.length > 0) {
          state.reqToShow =  singleRequest[0].status;
        } else {
          singleRequest = state.archive.filter((val) => ( val.req_id === action.payload.req_id)); 
          if(singleRequest.length > 0) 
            state.reqToShow =  singleRequest[0].status;
          else{
            state.error = <Translation data={"request.request_invalid"}  /> 
            state.findErrorRequest =true;
          }
        }

      },setDriverId :(state,action) => {
        state.driverId = action.payload;
      }
    },
    extraReducers: builder => {
        builder
          .addCase(createRequestAsync.pending, (state, action) => {
            state.status = 'loading';
            state.isLoading = true;
            state.error = null;
          })
          .addCase(createRequestAsync.fulfilled, (state, action) => {
            const newUuid = uuid();
            state.status = 'succeeded';
            state.isLoading = false;
            state.addedNewRequest=true;
            state.newRequest.req_id=action.payload;
            state.newRequest = {id: newUuid+ String (state.data.length),...state.newRequest}
            state.data = [...state.data,state.newRequest];
          })
          .addCase(createRequestAsync.rejected, (state, action) => {
            state.status = 'failed';
            state.isLoading = false;
            state.error = action.payload.error;
            console.log(action.payload.error);
          })
          .addCase(getRequestAsync.pending, (state, action) => {
            state.status = 'loading';
            state.isLoading = true;
            state.data =[];
            state.findErrorRequest = false;
            state.error = null;
          })
          .addCase(getRequestAsync.fulfilled, (state, action) => {
            const newUuid = uuid();
            state.status = 'succeeded';
            state.isLoading = false;
            state.error = null;
            action.payload.map((prev,index) => {
              state.data.push({id: newUuid+index,...prev})
            });
          })
          .addCase(getRequestAsync.rejected, (state, action) => {
            state.status = 'failed';
            state.isLoading = false;
            state.error = action.payload.error;
            state.findErrorRequest = true;
            console.log(action.payload.error);
          })
          .addCase(cancelRequestAsync.pending, (state, action) => {
            state.statusCancel = 'loading';
            state.error = null;
          })
          .addCase(cancelRequestAsync.fulfilled, (state, action) => {
            let newData= [];
            state.statusCancel = 'succeeded';

            state.data.forEach((val, index) => {
                
              if(val.req_id != state.reqIdCancel)
                newData.push(val)
              else{
                val.status = 8;
                state.archive.push(val)
              }
            });

            state.data = newData;

          })
          .addCase(cancelRequestAsync.rejected, (state, action) => {
            state.statusCancel = 'failed';
            state.error = action.payload.error;
            console.log(action.payload.error);
          })
          .addCase(getArchiveRequestAsync.pending, (state, action) => {
            state.status = 'loading';
            state.isLoading = true;
            state.findErrorRequest = false;
            state.archive =[];
            state.error = null;
          })
          .addCase(getArchiveRequestAsync.fulfilled, (state, action) => {
            const newUuid = uuid();
            state.status = 'succeeded';
            state.isLoading = false;
            action.payload.map((prev,index) => {
              state.archive.push({id: newUuid+index,...prev})
            });
          })
          .addCase(getArchiveRequestAsync.rejected, (state, action) => {
            state.status = 'failed';
            state.isLoading = false;
            state.error = action.payload.error;
            state.findErrorRequest = true;
            console.log(action.payload.error);
          })
          .addCase(getPathAndTimeEstAsync.pending, (state, action) => {
            state.status = 'loading';
            state.isLoading = true;
            state.error = null;
            state.data = [];
            state.path='' ;
            state.estimatedDuration='';
            state.findErrorRequest = false;
          })
          .addCase(getPathAndTimeEstAsync.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.isLoading = false;
            state.error = null;
            state.path = action.payload.routes[0].overview_polyline.points;
            state.estimatedDuration= action.payload.routes[0].legs[0].duration.text;
          })
          .addCase(getPathAndTimeEstAsync.rejected, (state, action) => {
            state.status = 'failed';
            state.isLoading = false;
            state.error = action.payload.error;
            state.path='' ;
            state.estimatedDuration='';
            state.findErrorRequest = true;
            console.log(action.payload.error);
          })
          .addCase(acceptOfferAsync.pending, (state, action) => {
            state.statusOffer = 'loading';
            state.error = null;
            state.findErrorRequest = false;
          })
          .addCase(acceptOfferAsync.fulfilled, (state, action) => {
            let newData = [];

            state.statusOffer = 'succeeded';
            state.error = null;
            state.data.forEach((val, index) => {
                
              if(val.req_id === state.reqIdCancel) {
                  val.status = 2; // change status
                  val.offers = state.offerObjects.filter((val) => val.offerId === state.offerSelected);
                  val.offers[0].OfferStatus = 1;   // change offer states;
              }
    
              newData.push(val);
            });
            state.data = newData;

            state.offerObjects = [];
          })
          .addCase(acceptOfferAsync.rejected, (state, action) => {
            state.statusOffer = 'failed';
            state.error = action.payload.error;
            state.findErrorRequest = true;
            console.log(action.payload.error);
          })
          .addCase(rejectedOfferAsync.pending, (state, action) => {
            state.status = 'loading';
            state.error = null;
            state.findErrorRequest = false;
          })
          .addCase(rejectedOfferAsync.fulfilled, (state, action) => {
            let newData = [];
            let checkFindOffer = [];
            state.status = 'succeeded';
            state.offerObjects = state.offerObjects.filter((val) => val.offerId !== state.offerSelected);
            // reset offer
            state.data.forEach((val, index) => {

              if(val.req_id  === state.reqIdCancel){
                val.offers = state.offerObjects;
                 // check find offer request state
                 checkFindOffer= state.offerObjects.filter((val) => val.OfferStatus === 0);
                 if(checkFindOffer.length === 0)
                  val.status = 0;
              }
              newData.push(val)
            });
            state.data = newData
          })
          .addCase(rejectedOfferAsync.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.payload.error;
            state.findErrorRequest = true;
            console.log(action.payload.error);
          })
          .addCase(applyCouponAsync.pending, (state, action) => {
            state.statusCoupon = 'loading';
            state.error = null;
            state.findErrorRequest = false;
          })
          .addCase(applyCouponAsync.fulfilled, (state, action) => {
            state.statusCoupon = 'succeeded';
            state.coupon = true;
            state.newAmount = action.payload.newAmount;

            let result = [];
            state.offerObjects.forEach((val, index) => {
    
              result.push(val);
              if( val.offerId === state.offerSelected ) 
                result[index].campaign_details  = action.payload;
  
             });
             state.offerObjects = result;
          })
          .addCase(applyCouponAsync.rejected, (state, action) => {
            state.statusCoupon = 'failed';
            state.error = action.payload.error;
            state.coupon = false;
            state.findErrorRequest = true;
            console.log(action.payload.error);
          })
          .addCase(removeCouponAsync.pending, (state, action) => {
            state.statusCoupon = 'loading';
            state.error = null;
            state.findErrorRequest = false;
          })
          .addCase(removeCouponAsync.fulfilled, (state, action) => {
            state.statusCoupon = 'succeeded';
            state.coupon = false;
            let result = [];
            state.offerObjects.forEach((val, index) => {
    
              result.push(val);
              if( val.offerId === state.offerSelected ) 
                 result[index].campaign_details  = null;
              
           });
           state.offerObjects = result;
          })
          .addCase(removeCouponAsync.rejected, (state, action) => {
            state.statusCoupon = 'failed';
            state.error = action.payload.error;
            state.coupon = true;
            state.findErrorRequest = true;
            console.log(action.payload.error);
          })
          .addCase(createPaymentAsync.pending, (state, action) => {
            state.statusPayment = "loading";
            state.isLoadingPayment = true;
            state.error = null;
            state.findErrorRequest = false;
           })
          .addCase(createPaymentAsync.fulfilled, (state, action) => {
            state.statusPayment = 'succeeded';
            state.isLoadingPayment = false;
           })
          .addCase(createPaymentAsync.rejected, (state, action) => {
            state.statusPayment = 'failed';
            state.isLoadingPayment = false;
            state.error = action.payload.error;
            state.findErrorRequest = true;
            console.log(action.payload.error);
          })
          .addCase(getSingleRequestAsync.pending, (state, action) => {
            state.statusSingleRequest = "loading";
            state.error = null;
            state.findErrorRequest = false;
           })
          .addCase(getSingleRequestAsync.fulfilled, (state, action) => {
            state.statusSingleRequest = 'succeeded';
            state.singleRequest = action.payload;
           })
          .addCase(getSingleRequestAsync.rejected, (state, action) => {
            state.statusSingleRequest = 'failed';
            state.error = action.payload.error;
            state.findErrorRequest = true;
            console.log(action.payload.error);
          })
          .addCase(getSingleRequestReviewAsync.pending, (state, action) => {
            state.statusSingleRequestReview = "loading";
            state.error = null;
            state.findErrorRequest = false;
           })
          .addCase(getSingleRequestReviewAsync.fulfilled, (state, action) => {
            state.statusSingleRequestReview = 'succeeded';
            state.singleRequestReview = action.payload;
           })
          .addCase(getSingleRequestReviewAsync.rejected, (state, action) => {
            state.statusSingleRequestReview = 'failed';
            state.error = action.payload.error;
            state.findErrorRequest = true;
            console.log(action.payload.error);
          })
          .addCase(updateSimilarRequestAsync.pending, (state, action) => {
            state.status = "loading";
            state.error = null;
            state.findErrorRequest = false;
           })
          .addCase(updateSimilarRequestAsync.fulfilled, (state, action) => {
            let similar = [];
            state.status = 'succeeded';
            state.archive.forEach((val, index) => {

              if(val.req_id  === state.reqIdCancel)
                val.starred = state.similar === 1 ? 0 :1;
              
                similar.push(val)
            });
            state.archive = similar;
            state.error = null;

           })
          .addCase(updateSimilarRequestAsync.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.payload.error;
            state.findErrorRequest = true;
            console.log(action.payload.error);
          })
          .addCase(getRequestUnRatedAsync.pending, (state, action) => {
            state.statusReview = "loading";
            state.findErrorRequest = false;
            state.error = null;
           })
          .addCase(getRequestUnRatedAsync.fulfilled, (state, action) => {
            state.statusReview = 'succeeded';
            state.requestUnRated = action.payload;  
            state.error = null;
           })
          .addCase(getRequestUnRatedAsync.rejected, (state, action) => {
            state.statusReview = 'failed';
            state.findErrorRequest = true;
            state.error = action.payload.error;
            console.log(action.payload.error);
          })
          .addCase(ratingAsync.pending, (state, action) => {
            state.statusReview = "loading";
            state.findErrorRequest = false;
            state.error = null;
           })
          .addCase(ratingAsync.fulfilled, (state, action) => {
            state.statusReview = 'succeeded';
            state.requestUnRated = state.requestUnRated.filter((val) => val.req_id !== state.reqIdCancel);    
            state.error = null;
           })
          .addCase(ratingAsync.rejected, (state, action) => {
            state.statusReview = 'failed';
            state.error = action.payload.error;
            state.findErrorRequest = true;
            console.log(action.payload.error);
          })
          .addCase(UpdateRequestAfterNotifyAsync.pending, (state, action) => {
            state.isLoading = true;
            state.status = 'loading';
            state.findErrorRequest = false;
            state.error = null;
           })
          .addCase(UpdateRequestAfterNotifyAsync.fulfilled, (state, action) => {
              const newUuid = uuid();
              state.isLoading = false;
              state.status = 'succeeded';
  
              // update full request
              state.data = state.data.filter((val) => ( val.req_id !== action.payload.req_id)); 
              action.payload = {id: newUuid+ String(state.data.length),...action.payload}
              state.data = [...state.data, action.payload];
               // end update full request
      
              state.reqIdCancel = action.payload.req_id;
              state.paymentType = action.payload.offers.payment_method;
              state.offerObjects = action.payload.offers;
              state.reqToShow =  action.payload.status;
            
           })
          .addCase(UpdateRequestAfterNotifyAsync.rejected, (state, action) => {
            state.isLoading = false;
            state.status = 'failed';
            state.error = action.payload.error;
            state.findErrorRequest = true;
            console.log(action.payload.error);
          })
      }
});


export const {setState,setReqIdCancel,setRequests,setReqToShow,setDescription,setPackageSize,setPickupAddressId,
  setDropAddressId,setDeliveryDate,setDeliveryTime,setSimilar,
  setPaymentMethodId,setSpecialCare,setInstructions,setActiveStep,setSkipped,resetValues,
  setAddressPickup,setAddressDrop,setPath,setEstimatedDuration,setDistance,setOfferObjects,setImgUrl,
  setOfferSelected,setCoupon,setnewAmount,setPaymentType,setOfferIdx,setStatuesPayment,showNotify,setDriverId
  ,setFindErrorRequest,cloneRequest,setNewRequest,setStatusSingleRequestReview,getStatuesForAllRequest } = requestSlice.actions;
export default requestSlice.reducer;