import axios from 'axios';
import {ApiException} from '../../utils/exceptions';
import { auth } from '../../config/firebase-config';

const ApiService = {

  async _axiosInstance(headersParams,options) {

   // check token 
    let token = '';
    await auth.currentUser.getIdToken().then(data => {
      token = `Bearer ${data}`;
    });
    
    return axios.create({
      baseURL: options.dynamicState.prodApiServerBaseUrl,
      headers: {
        'Authorization': ('authorization' in headersParams)? token:'',
        'Content-Type':('contentType' in headersParams)? headersParams.contentType:''
      }
    });
    
  },

    
  reformatData(contentType,data) {
    
    if(contentType === "application/json")
      return  JSON.stringify(data)
    
      return data
   },

  async patch(path,headers,data,options) {
   
    const axiosInstance =  await this._axiosInstance(headers,options);

    if(options.reformatData)
         data = this.reformatData(headers.contentType,data);

    try {

        const response = await axiosInstance.patch(path, data);
        return response;

    } catch (error) {

        throw new ApiException({
          name:"Patch Request Error",
          message: error.response ? error.response.data.error.message : null,
          errorCode: error.response ? error.response.status : null
        });
    }

  },

  async delete(path,headers,data,options) {
  
    const axiosInstance =  await this._axiosInstance(headers,options);
    
    if(options.reformatData)
        data = this.reformatData(headers.contentType,data);

    try {
        const response = await axiosInstance.delete(path,data);
        return response;

    } catch (error) {

        throw new ApiException({
          name:"Delete Request Error",
          message: error.response ? error.response.data.error.message : null,
          errorCode: error.response ? error.response.status : null
        });
    }
    
  },

  async put(path,headers,data,options) {

    const axiosInstance =  await this._axiosInstance(headers,options);

    if(options.reformatData)
         data = this.reformatData(headers.contentType,data);

    try {

        const response = await axiosInstance.put(path, data);
        return response;

    } catch (error) {

        throw new ApiException({
          name:"Put Request Error",
          message: error.response ? error.response.data.error.message : null,
          errorCode: error.response ? error.response.status : null
        });
    }
  },

  async post(path,headers,data,options) {
  
    const axiosInstance =  await this._axiosInstance(headers,options);
    
    if(options.reformatData)
        data = this.reformatData(headers.contentType,data);

    try {

        const response = await axiosInstance.post(path, data);
        return response;

    } catch (error) {

        throw new ApiException({
          name:"Post Request Error",
          message: error.response ? error.response.data.error.message : null,
          errorCode: error.response ? error.response.status : null
        });
    }
    
  },

  async get(path,headers,data,options) {

    const axiosInstance =  await this._axiosInstance(headers,options);

    if(options.reformatData)
       data = this.reformatData(headers.contentType,data);

    try {
      const response = await axiosInstance.get(path, data);
      return response;

    } catch (error) {
      throw new ApiException({
        name:"Get Request Error",
        message: error.response ? error.response.data.error.message : null,
        errorCode: error.response ? error.response.status : null
      });
    }
  }


};

export default ApiService;