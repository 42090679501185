import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import PackageSizeLabel from '../../components/label/PackageSizeLabel';
import {setDescription,setPackageSize} from '../../services/request/requestSlice';
import { useDispatch,useSelector } from 'react-redux';
import FormLabel from '@mui/material/FormLabel';
import Translation from '../../utils/Translation';

export default function PackageDescription() {

 const dispatch = useDispatch();
 const {description,packageSize} = useSelector(state => state.request)

  return (
    <>
    <Box
     sx={{
      width: 500,
      maxWidth: '100%',
      paddingTop:'3%',
      paddingBottom:'3%',
      paddingLeft:'2%'
    }}

    className="icon-arabic"
    >
        <TextField fullWidth label={<Translation data="request.pck_description"  />} id="package_description" value={description}  onChange = {(e) => dispatch(setDescription(e.target.value))} />
        
        <FormControl>
        <FormLabel variant="h6" component="h6" className='font-weight-600 mb-1 mt-2'>
           <Translation data="request.pck_size"  />
         </FormLabel>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group" >
               
                <FormControlLabel value={0} control={<Radio />} label={
                   <PackageSizeLabel value={0}   />
                } checked={packageSize === 0}  onChange={() => dispatch(setPackageSize(0)) } />
                <Typography variant="span" component="span" className='package-size'>
                   <Translation data="request.pck_size_desc_0"  />
                </Typography>

                <FormControlLabel value={1} control={<Radio />} label={
                   <PackageSizeLabel value={1}  />
                } checked={packageSize === 1} onChange={() =>  dispatch(setPackageSize(1))} />
                <Typography variant="span" component="span" className='package-size'>
                   <Translation data="request.pck_size_desc_1"  />
                </Typography>

                <FormControlLabel value={2} control={<Radio />} label={
                   <PackageSizeLabel value={2}    />
                } checked={packageSize === 2} onChange={() =>  dispatch(setPackageSize(2))}  />
                <Typography variant="span" component="span" className='package-size'>
                   <Translation data="request.pck_size_desc_2"  />
                </Typography>

                <FormControlLabel value={3} control={<Radio />} label={
                   <PackageSizeLabel value={3}  />
                } checked={packageSize === 3} onChange={() =>  dispatch(setPackageSize(3))} />
                <Typography variant="span" component="span" className='package-size'>
                   <Translation data="request.pck_size_desc_3"  />
                </Typography>

            </RadioGroup>
       </FormControl>
    </Box>


    </>
  )
}
