import React, { useRef, useState, useEffect } from 'react';
import HeaderChat from './HeaderChat';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { collection, addDoc, query, orderBy, onSnapshot  } from 'firebase/firestore';
import { db } from '../../config/firebase-config';
import ChatMessage from './ChatMessage'
import { setIsLoadingChat } from '../../services/chat/chatSlice';
import { useDispatch } from 'react-redux';
import {  getDownloadURL, ref as storageRef,  uploadBytes } from 'firebase/storage';
import {storage} from '../../config/firebase-config';
import { v1 as uuid } from 'uuid';
import Button from '@mui/material/Button';
import {styled} from '@mui/material';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { useTranslation } from 'react-i18next';
    

export default function SingleChat({changeWidget}) {

  const dispatch = useDispatch();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const {userProfile} = useSelector(state => state.profile);
  const {isLoadingChat,chatSingle,readOnly} = useSelector(state => state.chat);
  const [image,setImage]= useState('');
  const { t, i18n } = useTranslation();
  const inputFile = useRef(null);
  const endOfMessagesRef = useRef(null);
  
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  
  const scrollToBottom = () => {
      endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
      scrollToBottom();
  }, [messages]);


  useEffect(() => {
    const q = query(collection(db,`chats/${chatSingle.req_id}/offers/${chatSingle.offer_id}/${chatSingle.user_id}_${chatSingle.senderId}`), orderBy('time', 'desc'));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const messages = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      setMessages(messages.reverse());
      dispatch(setIsLoadingChat(false));  
    });
    return () => unsubscribe();
  }, []);


    const handleSendMessage = async (event) => {

      if(event)
        event.preventDefault();
      
      let messageNow = newMessage;

      setNewMessage(''); // reset value
      sendData(messageNow)
    };

    const sendData = async (messageNow) => {

      if (messageNow.trim() === "") {
        alert("Enter valid message");
        return;
      }

      const timeNow = Date.now();

      await addDoc(collection(db,`chats/${chatSingle.req_id}/offers/${chatSingle.offer_id}/${chatSingle.user_id}_${chatSingle.senderId}`), {
        api_server_notification_endpoint : "https://lb.deliveritapp-apis.xyz/v1",
        message: messageNow,
        req_id: chatSingle.req_id,
        sendBy: chatSingle.user_id,
        receiverName: (chatSingle?.nickname)?chatSingle.nickname:'',
        sendTo: chatSingle.senderId,
        senderName :(userProfile?.nickname)?userProfile.nickname:'',
        time: timeNow
      });

    };


    const handleChange = (e) => {
      if (e.target.files[0]) {
        setImage(e.target.files[0]);
        handleUpload()  // upload to firebase
      }
    };


    const handleUpload = () => {
    
      const imageRef = storageRef(storage, `chat_images/${uuid()}`);
  
       uploadBytes(imageRef, image)
        .then((snapshot) => {
          getDownloadURL(snapshot.ref)
            .then((imgUrl) => {
              inputFile.current.value = "";
              imgUrl=  `[Chat Image](${imgUrl})`;
              sendData(imgUrl);
            })
            .catch((error) => {
              console.log(error)
            });
        })
        .catch((error) => {
          console.log(error)
        });
    
    };

  return (
    <Box className="single-chat">
      
       <HeaderChat changeWidget={changeWidget} name={chatSingle.nickname} type="single-chat" />

      <section>
      {isLoadingChat ? <CircularProgress  className='chat-loading' />:
        <main>
          {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg}
            user_id={chatSingle.user_id} 
            userImg = {userProfile?.profile_image_url}
            driverImg = {chatSingle?.senderProfileImage}
            />)}


          <div ref={endOfMessagesRef} />
        </main>
        }
        {readOnly === false &&
          <form onSubmit={handleSendMessage}>
{/* 
             <TextField  
                className='input-send'
                value={newMessage} 
                label={<Translation data="chat.write_here"  />}
                type='text'
                onChange={(e) => setNewMessage(e.target.value)}
              />  */}
              <input   className='input-send'  value={newMessage} onChange={(e) => setNewMessage(e.target.value)} placeholder={t("chat.write_here")} />
              
              {
                newMessage !== '' ? 
                <Button type="submit" className='button-send-text' disabled={!newMessage}><img src={require('../../assets/image/layout/send-icon.png')}   /></Button>
                :
                <Button
                  className='button-send-img' 
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<InsertPhotoIcon />} >
                  <VisuallyHiddenInput  ref={inputFile}  onChange={handleChange} type="file" />
                </Button>
              }
       
            

          </form>
        }
       
      </section>

    </Box>
  );
}
