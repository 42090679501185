import React from 'react'
import RefreshIcon from '@mui/icons-material/Refresh';
import { Button } from '@mui/material';
import { getArchiveRequestAsync, getRequestAsync } from '../../services/request/requestSlice';
import { useDispatch,useSelector } from 'react-redux';

export default function RefreshButton() {

 const dispatch = useDispatch();
 const auth = useSelector(state => state.auth);
 const dynamicState = useSelector(state => state.dynamicConfig);

 const handleRefresh = ()=>{
  dispatch(getRequestAsync({auth,dynamicState}));
  dispatch(getArchiveRequestAsync({auth,dynamicState}))
 }




  return (
    <Button className='mb-1 refresh-button' onClick={()=>handleRefresh()}>
       <RefreshIcon />
     </Button>
  )
}
