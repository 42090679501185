import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setFindErrorChat } from '../../services/chat/chatSlice';
import { setFindErrorAddress } from '../../services/address/addressSlice';
import { setFindErrorRequest } from '../../services/request/requestSlice';
import { setFindErrorProfile } from '../../services/profile/profileSlice';

export default function ResultError(props) {

  const {error,findErrorRequest} = useSelector(state => state.request);
  const {errorAddress,findErrorAddress} = useSelector(state => state.address);
  const {errorChat,findErrorChat} = useSelector(state => state.chat);
  const {errorProfile,findErrorProfile} = useSelector(state => state.profile);

  const [open, setOpen] = React.useState(false);
  const [msg,setMsg] =  React.useState('');

  const dispatch = useDispatch();

  const handleClose = (event, reason) => {

    if (reason === 'clickaway') {
      return;
    }

    dispatch(setFindErrorChat(false));
    dispatch(setFindErrorAddress(false));
    dispatch(setFindErrorRequest(false));
    dispatch(setFindErrorProfile(false));
    setOpen(false);
  };

  useEffect(() => {

    if(findErrorRequest){
      setOpen(findErrorRequest);
      setMsg(error);
    }

    if(findErrorAddress){
      setOpen(findErrorAddress);
      setMsg(errorAddress);
    }

    if(findErrorChat) {
      setOpen(findErrorChat);
      setMsg(errorChat);
    }

    if(findErrorProfile) {
      setOpen(findErrorProfile);
      setMsg(errorProfile);
    }
   
  }, [dispatch,findErrorRequest,findErrorAddress,findErrorChat,findErrorProfile]);

  const action = (
    <React.Fragment>
      <IconButton
        size="large"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Box className="show-error">
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={msg}
        action={action}
      />
    </Box>
  );
}
