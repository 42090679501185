import React from 'react'
import { Box} from '@mui/material'
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import Translation from '../../utils/Translation';

export default function PackageSizeLabel(props) {
  return (
    <Box style={{ display: 'flex', alignItems: 'center' }}>
     {props.value === 0 &&
      <>
          <TwoWheelerIcon  style={{ marginRight: 8 }} />
          <Translation data="request.pck_size_title_0"  />
      </>
      }
     {props.value === 1 &&
     <>
        <DirectionsCarFilledIcon  style={{ marginRight: 8 }} />
        <Translation data="request.pck_size_title_1"  />
     </>
     
      }
      {props.value === 2 &&
     <>
        <LocalShippingIcon  style={{ marginRight: 8 }} />
        <Translation data="request.pck_size_title_2"  />
     </>
     
      }
      {props.value === 3 &&
     <>
        <CarRepairIcon  style={{ marginRight: 8 }} />
        <Translation data="request.pck_size_title_3"  />
     </>
      } 
    </Box>
  )
}

