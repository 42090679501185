import * as React from 'react';
import Box from '@mui/material/Box';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import { Typography } from '@mui/material';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import AvTimerOutlinedIcon from '@mui/icons-material/AvTimerOutlined';
import { Link } from 'react-router-dom';
import { Star } from '@mui/icons-material';
import {Badge} from '@mui/material';

export default function BoxRequest(props) {

  return (
    <Link to='/' className='text-undecoration' 
     onClick={()=> props.action(props.val.status,(props.val.name==="Similar"?1:0))}
      >
      <Box >
          <Container className='box-request' >
            <Grid container spacing={3}>
                <Grid>
                  {(props.val.name==="Similar") && <Star />}
                  {(props.val.name==="Archive") &&  <ArchiveOutlinedIcon />}

                 
                    {(props.val.name ==="Waiting For Driver") && 
                      <Badge badgeContent={props.val.counter > 0 ?props.val.counter:null} color="primary">
                        <AvTimerOutlinedIcon   />
                      </Badge>
                      }

                      {(props.val.name==="Has Offer") && 

                        <Badge badgeContent={props.val.counter > 0 ?props.val.counter:null} color="primary">
                           <LocalOfferOutlinedIcon   />
                        </Badge>
                      }

                      {(props.val.name==="In Progress") && 

                        <Badge badgeContent={props.val.counter > 0 ?props.val.counter:null} color="primary">
                          <AutorenewOutlinedIcon   />
                        </Badge>
                        }
                </Grid>

                <Grid lg={10} sm={8}  xs={7} className='update-request'>
                   <Typography variant="b" component="h3">
                      {props.val.label}  
                    </Typography>
                </Grid>

                <Grid lg={1} sm={3}   xs={3} className='dir-rtl'>
                    <ArrowCircleRightOutlinedIcon   />
                </Grid>
            </Grid>
          </Container>  
      </Box>
    </Link>
  );

}