import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {  Stack } from '@mui/material';
import ResultSend from '../alert/ResultSend';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Rating from '@mui/material/Rating';
import AddIcon from '@mui/icons-material/Add';
import { useSelector,useDispatch } from 'react-redux';
import  {differentDate} from '../../utils/helpers'
import {TextField} from '@mui/material';
import { applyCouponAsync,removeCouponAsync,setCoupon,setnewAmount,setOfferSelected } from '../../services/request/requestSlice';
import GetPeriod from '../date/GetPeriod';
import Translation from '../../utils/Translation';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

export default function OfferModal({openOffer,handleOfferClose,RejectOffer,AcceptOffer,generateStripIntent,msg,error}) {

const dispatch = useDispatch();
const {offerObjects,paymentType,isLoading,coupon,newAmount,statusCoupon} = useSelector(state => state.request);
const [applyCoupon,setApplyCoupon] = useState(false);
const [couponIdx,setCouponIdx] = useState(null);
const [code,setCode] = useState('');
const auth = useSelector(state => state.auth);
const dynamicState = useSelector(state => state.dynamicConfig);

const handleCouponOpen = (idx,val) => {

  // reset coupon when add another coupon
  if(idx !== couponIdx) {
    dispatch(setCoupon(false));
  }

  setCouponIdx(idx); // coupon idx
  setApplyCoupon(val);
} 

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  maxHeight: '80%',
  overflowY: 'scroll'
  };

const styleList = {
  py: 0,
  width: '100%',
  borderRadius: 2,
  borderColor: 'divider',
  backgroundColor: 'background.paper',
  };


const handleApplyCoupon = (idx) => {
     
  let data = {
    "code" : code,
    "amount": offerObjects[idx]?.offer_value_total,
    "offer_id":offerObjects[idx]?.offerId,
    "request_id":offerObjects[idx]?.req_id
  };

   dispatch(setOfferSelected(data["offer_id"]));
   dispatch(applyCouponAsync({auth,dynamicState,data}));
}


const handleRemoveCoupon= (idx) => {
  
  // campaign_details
  let data = {
    "code_id": offerObjects[idx]?.campaign_details?.codeId,
    "offer_id":offerObjects[idx]?.offerId,
    "request_id":offerObjects[idx]?.req_id
  };

   dispatch(setOfferSelected(data["offer_id"]));
   dispatch(removeCouponAsync({auth,dynamicState,data}));
}


useEffect (()=>{

  // when delete coupon
  if(coupon === false) {
    setCode('');
    dispatch(setnewAmount(null))
  } else {  // when apply coupon
    setApplyCoupon(false);
  }

},[coupon,dispatch]);


useEffect(()=>{

    if(statusCoupon === "succeeded") {
      
    }

},[statusCoupon,dispatch]);


  return (
    <Modal
    open={openOffer}
    onClose={handleOfferClose}
    id="modal-offer"
    aria-labelledby="child-modal-title"
    aria-describedby="child-modal-description">
    <Box sx={{ ...style, width: 500 }}>
        
      <Box sx={{ pt:2 }}>
          <Badge  color="primary" badgeContent={offerObjects?.filter(val => val.OfferStatus === 0)?.length} >
              <Chip label=<Translation data="offer.offer_available" />  />
          </Badge> 
      </Box>

      {offerObjects && offerObjects.map((offer,idx)=>{
         
        let date= differentDate(offer?.offer_created_at);

        if(offer.OfferStatus === 0 ){
          return (
          <Box  key={idx} sx={{pb:2}}>
            <Box sx={{ pt:2 ,display: 'flex' }} >
                <Stack direction="row" spacing={2}>
                  <Avatar alt= {offer?.nickname} src={offer?.profile_image_url} />
                </Stack>  
                <Box sx={{p:1}}>
                  <div className='drive-name'> {offer?.nickname}</div> 
                  <div className="offer-time">
                   {GetPeriod(date)}
                 </div>
                </Box>
            </Box>

              <Box sx={{display: 'flex' }}>
                {offer?.rating_value === null ?
                  <Rating name="read-only" readOnly/>
                  :
                  <Typography className='star-color' variant="p">        
                    {offer?.rating_value >= 1 ? <StarIcon  />:  <StarBorderIcon  />}
                    {offer?.rating_value >= 2 ? <StarIcon  />:  <StarBorderIcon  />}
                    {offer?.rating_value >= 3 ? <StarIcon  />:  <StarBorderIcon  />}
                    {offer?.rating_value >= 4 ? <StarIcon  />:  <StarBorderIcon  />}
                    {offer?.rating_value >= 5 ? <StarIcon  />:  <StarBorderIcon  />}
                  </Typography>
                }
              </Box>

              <Box className="reference">
               <Translation data="offer.reference" /> {offer?.offerId}
              </Box>
        
              <Box sx={{pb:1 }}>
                
                <List sx={styleList}>
                  <ListItem>
                      <ListItemText primary=<Translation data="offer.value" />  />
                    <Typography>{(offer?.offerValueWihtoutServiceCharges)? offer?.offerValueWihtoutServiceCharges :  offer?.offerValue} <Translation data="offer.aed" /> </Typography>
                  </ListItem>
                  <Divider variant="middle" component="li" />
                  <ListItem>
                      <ListItemText primary=<Translation data="offer.service_charges" /> />
                      <Typography> {((offer?.offerValueWihtoutServiceCharges)? offer?.offerValueWihtoutServiceCharges :  offer?.offerValue) * offer?.offer_service_charges_percentage} <Translation data="offer.aed" /> </Typography>
                  </ListItem>
                  <Divider variant="middle" component="li" />
                  <ListItem>
                      <ListItemText primary=<Translation data="offer.coupon" /> />

                      {applyCoupon && idx===couponIdx ?  
                        <Box sx={{width:"35%"}}>
                          <TextField onChange={(e)=>setCode(e.target.value)} disabled={coupon} value={code?code:''} id="outlined-basic"  label=<Translation data="offer.code" /> variant="outlined"  /> 

                          <Stack spacing={1} className='mt-0-5'>
                            <Button  variant="outlined" disabled={code!==""?false:true} className={coupon ? 'red' : ''}  onClick={coupon ? () => handleRemoveCoupon(idx): () => handleApplyCoupon(idx)}>{coupon ?<Translation data="offer.delete" />:<Translation data="offer.apply" />}</Button>
                            
                            <Button onClick={ () => handleCouponOpen(idx,false)} variant="outlined"><Translation data="offer.close" /></Button>
                          </Stack>
                        </Box>
                        :
                        <Button onClick={ () => handleCouponOpen(idx,true)} variant="outlined"> {coupon && idx===couponIdx?  <><Translation data="offer.edit" />  code </>:  <AddIcon />} </Button>}
                    
                  </ListItem>
                  <Divider variant="middle" component="li" />
                  <ListItem>
                      <ListItemText primary=<Translation data="offer.total" /> />
                      <Typography>{newAmount && idx===couponIdx ? newAmount : offer?.offer_value_total} <Translation data="offer.aed" /></Typography>
                  </ListItem>
                  <Divider variant="middle" component="li" />
                  <ListItem>
                      <ListItemText primary=<Translation data="offer.chargeable_amount" /> />
                      <Typography>{newAmount && idx===couponIdx ? newAmount : offer?.offer_value_total} <Translation data="offer.aed" /></Typography>
                  </ListItem>
                </List>
              </Box>

              <Stack spacing={1}>
                  <Button variant="outlined" endIcon={<CheckBoxIcon />} onClick={paymentType ===0?  () => generateStripIntent(idx)   : () => AcceptOffer(idx)} className='accept'><Translation data="offer.accept" /></Button>
                  <Button variant="outlined" size='small' color="error"  endIcon={<DeleteIcon />} onClick={()=>RejectOffer(idx)} className='reject'><Translation data="offer.reject" /></Button> 
              </Stack>   
          </Box>  )
          }
  
      })}
  
      {!isLoading && 
        <Box className="mt-1">
        <ResultSend msg={msg}  error={error?true:false} />
      </Box>
      }
  
      <Stack className='mt-2'>
          <Button variant="outlined"  onClick={handleOfferClose}><Translation data="offer.close" /></Button> 
      </Stack>
    </Box>
    </Modal>
  )
}
