import ApiService from '../api/api_service';
import {ApiException} from '../../utils/exceptions';

const AddressService = {

    async loadAddress({auth,dynamicState}) {
     
        const path = '/v2/favorites';
        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
        const data = {}
        const options = {"dynamicState":dynamicState,"reformatData":true}

      try {

        const response = await ApiService.get(path,headers,data,options);
        const addresses = response.data; 
          
        return addresses
       
        } catch(error) {
            throw new ApiException(error)
        }
        
     
    },


    async createAddress({auth,dynamicState,data}) {
     
        const path = '/v2/favorites';
        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
        const options = {"dynamicState":dynamicState,"reformatData":false}
        data = {'address_data':  JSON.stringify(data)};

      try {
      

        
        const response = await ApiService.post(path,headers,data,options);
        const addresses = response.data; 
          
        return addresses
       
        } catch(error) {
        
            throw new ApiException(error)
        }

    },

    async updateAddress({auth,dynamicState,data,addressId}) {
     
        const path = '/v2/favorites';
        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
        const options = {"dynamicState":dynamicState,"reformatData":false}
        data = { "fav_id": addressId,'address_data':  JSON.stringify(data)};

      try {
      
        const response = await ApiService.put(path,headers,data,options);
        const addresses = response.data; 
          
        return addresses
       
        } catch(error) {
        
            throw new ApiException(error)
        }

    },

    async deleteAddress({auth,dynamicState,data}) {
     
        const path = '/v2/favorites';
        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
        const options = {"dynamicState":dynamicState,"reformatData":false}

      try {
        data = {data : JSON.stringify(data)};
        const response = await ApiService.delete(path,headers,data,options);
        const addresses = response.data; 
          
        return addresses
       
        } catch(error) {
        
            throw new ApiException(error)
        }

    },


    async checkAddressInUae({auth,dynamicState,coordinates}) {
     
     
        if(coordinates.constructor === Object){
            coordinates = coordinates.lat +","+coordinates.lng;
        }
     
        const path = `/v4/requests/coordinates/within/uae/${coordinates}`;
        const headers = {
            'authorization': `Bearer ${auth.user.accessToken}`,
            'contentType':"application/json"
        };
        const data = {}
        const options = {"dynamicState":dynamicState,"reformatData":false}

      try {

        const response = await ApiService.get(path,headers,data,options);
        const address = response.data; 
          
        return address
       
        } catch(error) {
            throw new ApiException(error)
        }
        
     
    }


};
export default AddressService;