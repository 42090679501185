import { useEffect } from 'react';
import {remoteConfig} from '../../config/firebase-config'; // Assuming you have a Firebase config file
import { fetchAndActivate, getString, getNumber, getBoolean } from "firebase/remote-config";


var _defaultProdApiServerBaseUrl = 'https://lastserver-deliverit-api-server-6k5m2.ondigitalocean.app';
var _defaultProdAdminServerBaseUrl = 'https://deliverit-admin-api-server-7ojcs.ondigitalocean.app';
var _defaultDevAdminServerBaseUrl = 'https://deliverit-admin-apis.tunnelto.';
var _defaultDevelopmentApiServerBaseUrl = 'https://deliverit-app-apis.tunnelto';
var _defaultMaximumOfferValue = 1000;
var _defaultMinimumDriverOfferExpiryInMinutes = 180;
var _defaultRestrictMultipleSignIns = true;
var _defaultEnableSoundFx = true;
var _defaultSupportWhatsAppNumber = '971506824339';
// var _defaultAdminAccountIds = '';
var _defaultDisableDriverTracking = false;
var _defaultDisableTwilioAuth = false;
var _defaultMultitenancyEnabled = false;
var _defaultCashPaymentEnabled = false;

const DynamicConfigService = () => {

  // default values
  const defaultProdApiServerBaseUrl = 'https://lastserver-deliverit-api-server-6k5m2.ondigitalocean.app';
  const defaultProdAdminServerBaseUrl = 'https://deliverit-admin-api-server-7ojcs.ondigitalocean.app';
  const defaultDevAdminServerBaseUrl = 'https://deliverit-admin-apis.tunnelto.';
  const defaultDevelopmentApiServerBaseUrl = 'https://deliverit-app-apis.tunnelto';
  const defaultMaximumOfferValue = 1000;
  const defaultMinimumDriverOfferExpiryInMinutes = 180;
  const defaultRestrictMultipleSignIns = true;
  const defaultEnableSoundFx = true;
  const defaultSupportWhatsAppNumber = '971506824339';
  const defaultAdminAccountIds = '';
  const defaultDisableDriverTracking = false;
  const defaultDisableTwilioAuth = false;
  const defaultMultitenancyEnabled = false;
  const defaultCashPaymentEnabled = false;

  useEffect(() => {
    const loadDynamicConfigurations = async () => {
      try {

        await remoteConfig.setConfigSettings({
          fetchTimeoutMillis: 10000,
          minimumFetchIntervalMillis: 5000,
        });

        await remoteConfig.setDefaults({
          prod_api_url: defaultProdApiServerBaseUrl,
          prod_admin_url: defaultProdAdminServerBaseUrl,
          dev_admin_url: defaultDevAdminServerBaseUrl,
          dev_api_url: defaultDevelopmentApiServerBaseUrl,
          offer_max_value: defaultMaximumOfferValue,
          offer_min_expiry_in_mins: defaultMinimumDriverOfferExpiryInMinutes,
          restrict_multiple_sign_ins: defaultRestrictMultipleSignIns,
          enable_sound_fx: defaultEnableSoundFx,
          support_whatsapp_number: defaultSupportWhatsAppNumber,
          admin_ids: defaultAdminAccountIds,
          disable_driver_tracking: defaultDisableDriverTracking,
          disable_twilio_auth: defaultDisableTwilioAuth,
          multitenancy: defaultMultitenancyEnabled,
          cash_payment_enabled: defaultCashPaymentEnabled,
        });

        await fetchDynamicConfigurations(remoteConfig);
        remoteConfig.onFetchAndActivate(() => fetchDynamicConfigurations(remoteConfig));
      } catch (error) {
        console.error('Error while loading remote config:', error);
      }
    };

    loadDynamicConfigurations();
  }, []);

  

  return null; 
};
const fetchDynamicConfigurations = async () => {
  try {
    return await fetch();
  } catch (error) {
    console.error('Error fetching dynamic configurations:', error);
    throw error; // Rethrow the error so it can be caught where the function is called
  }
};

const fetch = async () => {
  await fetchAndActivate(remoteConfig);
  _defaultProdApiServerBaseUrl = getString(remoteConfig, 'prod_api_url');
  _defaultProdAdminServerBaseUrl = getString(remoteConfig, 'prod_admin_url');
  _defaultDevAdminServerBaseUrl = getString(remoteConfig, 'dev_admin_url');
  _defaultDevelopmentApiServerBaseUrl = getString(remoteConfig, 'dev_api_url');
  _defaultMaximumOfferValue = getNumber(remoteConfig, 'offer_max_value');
  _defaultMinimumDriverOfferExpiryInMinutes = getNumber(remoteConfig, 'offer_min_expiry_in_mins');
  _defaultRestrictMultipleSignIns = getBoolean(remoteConfig, 'restrict_multiple_sign_ins');
  _defaultEnableSoundFx = getBoolean(remoteConfig, 'enable_sound_fx');
  _defaultSupportWhatsAppNumber = getString(remoteConfig, 'support_whatsapp_number');
  // _defaultAdminAccountIds = getString(remoteConfig, 'admin_ids');
  _defaultDisableDriverTracking = getBoolean(remoteConfig, 'disable_driver_tracking');
  _defaultDisableTwilioAuth = getBoolean(remoteConfig, 'disable_twilio_auth');
  _defaultMultitenancyEnabled = getBoolean(remoteConfig, 'multitenancy');
  _defaultCashPaymentEnabled = getBoolean(remoteConfig, 'cash_payment_enabled');
  const values = {
    prodApiServerBaseUrl: _defaultProdApiServerBaseUrl,
    // prodApiServerBaseUrl: "http://192.168.1.43:3000/",
    prodAdminServerBaseUrl: _defaultProdAdminServerBaseUrl,
    devAdminServerBaseUrl: _defaultDevAdminServerBaseUrl,
    developmentApiServerBaseUrl: _defaultDevelopmentApiServerBaseUrl,
    maximumOfferValue: _defaultMaximumOfferValue,
    minimumDriverOfferExpiryInMinutes: _defaultMinimumDriverOfferExpiryInMinutes,
    restrictMultipleSignIns: _defaultRestrictMultipleSignIns,
    enableSoundFx: _defaultEnableSoundFx,
    supportWhatsAppNumber: _defaultSupportWhatsAppNumber,
    // adminAccountIds: _defaultAdminAccountIds,
    disableDriverTracking: _defaultDisableDriverTracking,
    disableTwilioAuth: _defaultDisableTwilioAuth,
    multitenancyEnabled: _defaultMultitenancyEnabled,
    cashPaymentEnabled: _defaultCashPaymentEnabled
  };
  return values
} 

export { fetchDynamicConfigurations };

export default DynamicConfigService;
