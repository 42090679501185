import React from 'react'
import { Box } from '@mui/material'

export default function LogoAuth() {
  return (
    <Box className="margin-logo">
       <img loading="lazy"  src={require('../../assets/image/auth/logo.png')} alt="logo" className='auth-logo' />
    </Box>
  )
}


