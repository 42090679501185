import React from 'react';
import { MapControl } from '@vis.gl/react-google-maps';
import { useRef, useEffect, useState } from 'react';
import { useMapsLibrary } from '@vis.gl/react-google-maps';


export const CustomMapControl = ({
  handleCoordinates,
  handleCity,
  controlPosition,
  onPlaceSelect
}) => {

  const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
  const inputRef = useRef(null);
  const places = useMapsLibrary('places');

  useEffect(() => {

    if (!places || !inputRef.current) return;

    const options = {
      fields: ['geometry', 'name', 'formatted_address']
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {

    if (!placeAutocomplete) return;

    placeAutocomplete.addListener('place_changed', () => {

      const newCenter = {
                  lat: placeAutocomplete.getPlace().geometry?.location?.lat(),
                  lng: placeAutocomplete.getPlace().geometry?.location?.lng()
                };
      
      //set coordinates
      handleCoordinates(newCenter);

      // set city
      handleCity(placeAutocomplete.getPlace()?.formatted_address);

      onPlaceSelect(placeAutocomplete.getPlace());
    });

  }, [onPlaceSelect, placeAutocomplete]);



  return (
    <MapControl position={controlPosition}>
      <div className="autocomplete-control z-index-9999">
            <div className="autocomplete-container">
            <input
                id="searchInput"
                ref={inputRef} 
                onChange={()=> handleCoordinates('')}
              />
            </div>
      </div>
    </MapControl>
  );
};
