import { useMap } from '@vis.gl/react-google-maps';
import React, { useEffect } from 'react';
import { googleMapsApiKey } from '../../utils/helpers';
import { Button } from '@mui/material';
import Translation from '../../utils/Translation';

const MapHandler = ({ place ,handleCity,handleCoordinates }) => {
  const map = useMap();

  const getPlaces = async (lat, lng) => {
    const apiKey = googleMapsApiKey();
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
  
      try {
        const response = await fetch(geocodeUrl);
        const data = await response.json();
        if (data.status === "OK") {
  
          // change place
          const placeDetails = data.results[0];
          const formattedAddress = placeDetails.formatted_address;
        
           // set city
           handleCity(formattedAddress);

           // set Coordinates
           handleCoordinates({lat: lat, lng: lng});
           
          const bounds = new window.google.maps.LatLngBounds();
          bounds.extend(new window.google.maps.LatLng(lat,lng));
          map.fitBounds(bounds);

          window.google.maps.event.addListenerOnce(map, 'idle', () => {
            // Set the desired zoom level
            map.setZoom(15); // Change 7 to your desired zoom level
          });
          // end change place

          document.getElementById("searchInput").value = formattedAddress;

        } else {
          console.error("Geocode API error:", data.status);
        }
      } catch (error) {
        console.error("Error fetching city name:", error);
      }
  
      return "Unknown City";
  };
  
  
    const handleSetLocation = () => {
  
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async(position) => {
          const { latitude, longitude } = position.coords;
          //Fetch the city name using Google Maps Geocoding API
           await getPlaces(latitude, longitude);
        });
      } else {
        alert('Geolocation is not supported by this browser.');
      }
    };

    
  useEffect(() => {
    if (!map || !place) return;

    if (place.geometry?.viewport) {
      map.fitBounds(place.geometry?.viewport);
    }
  }, [map, place]);

 
  return (
    <>
    <Button variant="contained"  className='font-weight-600 z-index-9999' onClick={handleSetLocation}>
       <Translation  data="address.button_location"  />
    </Button>
    </>
 
  )
};

export default React.memo(MapHandler);
