import React,{useState}  from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import { useEffect } from 'react';
import {cancelRequestAsync, rejectedOfferAsync, setOfferSelected, setReqIdCancel,acceptOfferAsync,
   setCoupon,setPaymentType, createPaymentAsync, setOfferIdx,setStatuesPayment,
   updateSimilarRequestAsync,
   resetValues,
   setActiveStep,
   cloneRequest,setOfferObjects,setReqToShow} from '../../services/request/requestSlice'
import { useDispatch,useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import BreadcrumbRequest from '../breadcrumb/BreadcrumbRequest';
import RequestCancelModal from '../modal/RequestCancelModal';
import OfferModal from '../modal/OfferModal';
import FormPayment from '../modal/ModalPayment';
import { useNavigate } from 'react-router-dom';
import { setRoute, setValueTab } from '../../services/globalSlice';
import ShowRequest from '../dialog/ShowRequest';
import Translation from '../../utils/Translation';
import { useTranslation } from 'react-i18next';
import { arSD } from '@mui/x-data-grid/locales';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import RefreshButton from '../button/RefreshRequestButton';


const statusVal = [<Translation data="home.rquest_state_0"  />,<Translation data="home.rquest_state_1"  />,
  <Translation data="home.rquest_state_2"  />,<Translation data="home.rquest_state_3"  />,
  <Translation data="home.rquest_state_4"  />,<Translation data="home.rquest_state_5"  />
  ,<Translation data="home.rquest_state_6"  />,<Translation data="home.rquest_state_7"  />,
  <Translation data="home.rquest_state_8"  />,<Translation data="home.rquest_state_9"  />
];

const packageSize = [<Translation data="request.pck_size_title_0"  />,<Translation data="request.pck_size_title_1" />, 
<Translation data="request.pck_size_title_2" />, <Translation data="request.pck_size_title_3"/> ];


export default function DataTable(props) {

   const dispatch = useDispatch();
   const {statusCancel,isLoading,statusOffer,error,reqToShow,similar,reqIdCancel,data,archive,offerObjects,statusPayment} = useSelector(state => state.request);
   const auth = useSelector(state => state.auth);
   const dynamicState = useSelector(state => state.dynamicConfig);
   const [rows,setRows] = useState ([]);
   const [loadingData,setLoadingData] = useState(false)
   const [open, setOpen] = useState(false);
   const [openOffer, setOpenOffer] = useState(false);
   const [feedbackCancel,setFeedbackCancel] = useState('');
   const [clientSecret, setClientSecret] = useState('');
   const [showFormPayment,setShowFromPayment] = useState(false);
   const [msg,setMsg]=useState('');
   const navigator = useNavigate();
   const { t, i18n } = useTranslation();
   const checkArchive = () => {
    return (reqToShow===9 || reqToShow===8 || reqToShow===7);
   } 

   const theme = useTheme();

   const existingTheme = useTheme();
   const theme_ar = React.useMemo(
     () =>
       createTheme({}, arSD, existingTheme, {
        
       }),
     [existingTheme],
   );

   const columns = [
    { field: 'description', headerName:t("request.pck_description"),width: 180  },
    { field: 'package_size', headerName:t("request.pck_size_table"),width: 180,renderCell: (params) => {
      return  packageSize[params.row.package_size]
       }    
      },
    { field: 'delivery_date', headerName: t("request.delivery_date_table"),width: 120 },
    { field: 'estimated_duration', headerName: t("request.estimated_travel_table"),width: 120 },
    { field: 'status', headerName: t("request.status"),width: 200,renderCell: (params) => {
          return   <Box className="font-bold"> {statusVal[params.row.status]}</Box>  
     }    
    },
    { field: 'Action',headerName:  t("request.actions"),width: 330,renderCell: (params) => {

      let details =  <ShowRequest data={params.row} />;

      let removeSimilarButton  = (checkArchive() && params.row.starred=== 1 && similar === 1)?
             <>
              <Button  size="small" className='mr-0-5' variant="outlined" color="error" onClick={() =>handleSimilarRequest(params.row.req_id,0)} >
                  <Translation data="request.unsimilar"  />
              </Button>
              <Button  size="small" className='mr-0-5' variant="outlined" onClick={() =>handleMakeSimilarRequest(params.row.req_id)} >
                  <Translation data="request.make_Request"  />
              </Button>
             </>
                :'';

      let addSimilarButton  = (checkArchive() && params.row.starred=== 0 && similar === 0)?<Button onClick={() =>handleSimilarRequest(params.row.req_id,1)} 
                     size="small" className='mr-0-5' variant="outlined"> <Translation data="request.similar"  /></Button> :''

      let cancelButton = (!checkArchive())? <Button onClick={() =>handleOpen(params.row.req_id)} 
                     size="small" className='mr-0-5' variant="outlined" color="error"> <Translation data="request.cancel"  /> </Button> :''


      let offerDetails = reqToShow === 1? <Button onClick={() =>handleOfferOpen(params.row.req_id)} 
                     size="small"className='mr-0-5'   variant="outlined" > <Translation data="request.Offers"  />  </Button> :''

      return (
       <Box >
        {details}
        {removeSimilarButton}
        {addSimilarButton}
        {offerDetails}
        {cancelButton}
       </Box>
      )    
    }    
    }];
    
  const handleMakeSimilarRequest = (req_id) => {
      
    // reset request firstly
    dispatch(setActiveStep(0));
    dispatch(resetValues());
    
    // set request
    let requestSimilar = archive.filter((val) => (val.req_id === req_id ))[0];
    let newRoute = '/add_request';
    dispatch(cloneRequest(requestSimilar));

     // redirect request
     dispatch(setValueTab(1)); // set tab add request
     setRoute(setRoute(newRoute));   // set new route
     navigator(newRoute); // set new page by route
  }
    
  const handleSimilarRequest = (req_id,value) => {
    dispatch(setReqIdCancel({reqIdCancel : req_id}));   
    dispatch(updateSimilarRequestAsync({auth,dynamicState,req_id,value}));
  }

  const handleOpen = (req_id) => {
    setOpen(true);
    dispatch(setReqIdCancel({reqIdCancel : req_id}));     
  };

  const handleClose = () => {
    dispatch(setReqIdCancel({reqIdCancel :null }));
    setFeedbackCancel('');
    setOpen(false);
  };

  const handleOfferOpen = (req_id) => {
    setOpenOffer(true);
    let offer = data.filter((val) => val.req_id === req_id);
    dispatch(setPaymentType(offer[0].payment_method));
    dispatch(setReqIdCancel({reqIdCancel :req_id })); 
    dispatch(setOfferObjects(offer[0].offers));
  };

  const handleOfferClose = () => {
     setOpenOffer(false);
     dispatch(setReqIdCancel({reqIdCancel :null })); 
     dispatch(setOfferObjects(null));
     dispatch(setOfferSelected(null))
     dispatch(setCoupon(false));
     dispatch(setPaymentType(null));
  };

  const handleMsg = (val) =>{
    setMsg(val)
  }

  useEffect(()=>{

    setLoadingData(false);
    filterRequests();
  
  },[data,archive,reqToShow]);


   const filterRequests = () => {

      if(reqToShow >= 7) {

        if(similar == 1)
          setRows(archive.filter((val) => val.starred == similar)); 
        else  
          setRows(archive); 

      } else 
      if(reqToShow >= 2 && reqToShow <= 6)
        setRows(data.filter((val) => (val.status >= 2 && val.status <= 6)));     
      else
        setRows(data.filter((val) => (val.status ==  reqToShow)));     
     
        setLoadingData(true);
   }

  const cancelRequest = (e) => {
    e.preventDefault()
   
    let data = {req_id:reqIdCancel, feedback: feedbackCancel};
   
    dispatch(cancelRequestAsync({auth,dynamicState,data}));
  }; 


  useEffect(()=>{

    if(statusCancel === "succeeded") 
      handleClose();

    if(statusCancel === "failed")
      handleMsg(error)

  },[statusCancel,dispatch]);
  

  const AcceptOffer = (idx) => {

    let offer_id = offerObjects[idx].offerId;
    let object = {
      req_id:offerObjects[idx].req_id,
      offer_id: offerObjects[idx].offerId
    };
    
    dispatch(setOfferSelected(offer_id))
    dispatch(acceptOfferAsync({auth,dynamicState,object}));
  }

  
  useEffect(()=>{

    if(statusOffer === "succeeded") 
      handleOfferClose();

  },[statusOffer,dispatch]);

  
  const RejectOffer = (idx) => {

    let offer_id = offerObjects[idx].offerId;
    let request = data.filter((val) => val.req_id === offerObjects[idx].req_id);
    let object = {
      driver_id:offerObjects[idx].DriverId,
      request_id:offerObjects[idx].req_id,
      requester_nickname:offerObjects[idx].nickname,
      request_description:request[0].description
    };

    dispatch(setOfferSelected(offer_id))
    dispatch(rejectedOfferAsync({auth,dynamicState,offer_id,object}));
  }

  const generateStripIntent = async(idx) => { 
  
    dispatch(setOfferIdx(idx));

    let data = {
      req_id:offerObjects[idx]?.req_id,
      offer_id:offerObjects[idx]?.offerId,
      request_id:offerObjects[idx]?.req_id
    };
  
    let result = await dispatch(createPaymentAsync({auth,dynamicState,data}));
    setClientSecret(result.payload.client_secret);
  }

  useEffect(()=>{
   
    if(statusPayment === "succeeded"){
      setShowFromPayment(true);
      dispatch(setStatuesPayment("idle")); // for not open form again and reset for new payment
    }
   
  },[dispatch,statusPayment]);


  return (
    <Box  style={{ width: '100%' }}>

            <RequestCancelModal
              open = {open}   
              handleClose= {handleClose}    
              cancelRequest = {cancelRequest}
              feedbackCancel = {feedbackCancel}
              setFeedbackCancel = {setFeedbackCancel}
              msg = {msg}
              error = {error}
              />

            <OfferModal
              openOffer = {openOffer}     
              handleOfferClose = {handleOfferClose} 
              RejectOffer = {RejectOffer}
              AcceptOffer= {AcceptOffer}
              generateStripIntent = {generateStripIntent}
              msg = {msg}
              error = {error}
            />

            {showFormPayment &&
              <FormPayment
                clientSecret = {clientSecret}
                showFormPayment = {showFormPayment}
                setShowFromPayment = {setShowFromPayment}
                AcceptOffer= {AcceptOffer}
                />
            }

            { (loadingData && !isLoading) ? 
              <>
              <BreadcrumbRequest />
              <Box sx={{display:"inline-flex"}}>
                <Button   onClick={()=> dispatch(setReqToShow({reqToShow:null}))}>
                      <ArrowCircleLeftOutlinedIcon className="back-to-home"  />
                </Button>

                <Typography variant="h5" component="h5" className='font-weight-600 mb-1 mt-1'>
                      {(checkArchive() && similar===1) &&    <Translation data="home.similar"  /> }
                      {(checkArchive() && similar===0) && <Translation data="home.archive"  />  }
                      {(props.status === 0) && <Translation data="home.waiting_driver"  />  }
                      {(props.status=== 1)  && <Translation data="home.has_offer"  /> }
                      {(props.status >= 2 && props.status <= 6)  && <Translation data="home.in_progress"  /> }
                </Typography>

                <Box className="mt-1 no-padding-top">
                   <RefreshButton  />
                </Box>
              
              </Box>


                <ThemeProvider  theme={(localStorage.getItem("i18nextLng") === "ar")  ? theme_ar : theme }>
                    <DataGrid
                        className="table-request" 
                        rows={rows}
                        columns={columns}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                          },
                        }}
                        pageSizeOptions={[5, 10,15,20,50,100]}
                      />
                </ThemeProvider> 
              </>
                : <CircularProgress className='preloader-position' />
            }

    </Box>
  );
}

