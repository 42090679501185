import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  prodApiServerBaseUrl: null,
  prodAdminServerBaseUrl:null,
  devAdminServerBaseUrl: null,
  developmentApiServerBaseUrl: null,
  maximumOfferValue: 1000,
  minimumDriverOfferExpiryInMinutes: 180,
  restrictMultipleSignIns: true,
  enableSoundFx: true,
  supportWhatsAppNumber: '971506824339',
  adminAccountIds: '',
  disableDriverTracking: false,
  disableTwilioAuth: false,
  multitenancyEnabled: false,
  cashPaymentEnabled: false
};

const dynamicConfigSlice = createSlice({
  name: 'dynamicConfig',
  initialState,
  reducers: {
    setDynamicConfigurations: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    }
  }
});

export const getDynamicConfigurations = state => state.dynamicConfig;

export const { setDynamicConfigurations } = dynamicConfigSlice.actions;
export default dynamicConfigSlice.reducer;