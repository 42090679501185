import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {sendOtpRequest,setStatus,setError, setIsClick} from '../../services/auth/authSlice';
import FormHelperText from '@mui/material/FormHelperText';
import {useNavigate} from "react-router-dom";
import Copyright from './Copyright';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import LogoAuth from './LogoAuth';
import Translation from  '../../utils/Translation';

const defaultTheme = createTheme();

export default function Login() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isClick, verificationId,user,status, error} = useSelector(state => state.auth);
  const [phoneNumberInput, setPhoneNumberInput] = useState(localStorage.getItem("phoneNumber")?localStorage.getItem("phoneNumber"):'');
  const [confirmTerm,setConfirmTerm] = useState(false)

  const handlePhoneNumberInput = (val) => {

    setPhoneNumberInput(val);

    if(val.length === 12 && confirmTerm)
      dispatch(setIsClick(false)); 
    else
      dispatch(setIsClick(true)); 

  }

  const handleConfirmTerm = (val) => {

    setConfirmTerm(val)

    if(phoneNumberInput && phoneNumberInput.length === 12 && val)
      dispatch(setIsClick(false)); 
    else
      dispatch(setIsClick(true)); 

  }

  const handleSendOtp =  (e) =>  {

    e.preventDefault();

    if(!phoneNumberInput)
      dispatch(setError({error:"phone is Empty"}));
    else
      dispatch(sendOtpRequest({phoneNumberInput}));

  }

// when open page
  useEffect(()=>{
    dispatch(setIsClick(true)); 
  },[]);

  // check request and send to confirm page
  useEffect(()=>{

      if(status === "succeeded"){
        localStorage.setItem('verificationId', verificationId);
        localStorage.setItem('phoneNumber', phoneNumberInput);
        navigate('/confirm-otp');
        dispatch(setStatus({status:"idle"})); // reset status
      }

  },[status,dispatch]);


  // check user is login for redirect to home page
  useEffect(()=>{

    if(user)
       navigate('/');

    // find verification code
    if(localStorage.getItem("verificationId") != null)
      navigate('/confirm-otp');

  },[user]);


  return (
     (!user) && 
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box className='login-form'>

          <LogoAuth  />
      
          <Typography className='form-title'  component="h1" variant="b"> <Translation data="auth.title" />  </Typography>
          
          <Box component="form"  noValidate sx={{ mt: 1,width:"100%" }} onSubmit={handleSendOtp}>
    
            <TextField
            InputProps={{
            startAdornment:    <SmartphoneIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />,
            }}
            onInput = {(e) =>{
               e.target.value = e.target.value.slice(0,12)
            }}
              margin="normal"
              fullWidth
              id="phone"
              placeholder='971 phone number'
              label={<Translation data="auth.field"  />}
              name="phone"
              autoComplete="phone"
              type='number'
              value={phoneNumberInput}
              onChange={e => handlePhoneNumberInput(e.target.value)}
              autoFocus
            />

            <FormGroup> 
              <FormControlLabel   control={<Checkbox checked={confirmTerm} onChange={() => handleConfirmTerm(!confirmTerm)} />} label={
                <Box>
                  <Typography component='b'><Translation data="auth.terms_service_1" />  </Typography>
                  <Link to={'https://deliverit.ae/terms-of-service/'} target='blank' className='terms-of-service'><Translation data="auth.terms_service_2" />  </Link>
               </Box>
              } />
            </FormGroup>

              <FormHelperText className='error'>
              { ((status && status === "failed") || (error))? error:'' }
              </FormHelperText>

            <Button
              type="submit"
              disabled={isClick}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}>
                <Translation data="auth.title" />
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
     
  );
}




