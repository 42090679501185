import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function Notification(props) {

  const [open, setOpen] = React.useState(props.open);

  const handleClose = (event, reason) => {

    if (reason === 'clickaway') {
      return;
    }
  
    props.setSingleNotify(null);

    setOpen(false);
  };

  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="info"
          variant="filled"
          sx={{ width: '100%' }}
          className='notify'
        >
          {props.notification?.title  + " , " + props.notification?.body}
        </Alert>
      </Snackbar>
    </div>
  );
}