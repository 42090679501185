import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined';
import {setReqToShow} from '../../services/request/requestSlice';
import { useDispatch } from 'react-redux';
import Translation from "../../utils/Translation"
import { Box } from '@mui/material';

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

export default function BreadcrumbRequest() {

  const dispatch = useDispatch();

  return (
    <Box role="presentation" onClick={handleClick}>

    
  

      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
          color="inherit"
          onClick={()=> dispatch(setReqToShow({reqToShow:null}))}
          href="/"
        >
          <HomeOutlinedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          <Translation  data="home.all_requests"  />
        </Link>
      
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
          <TableRowsOutlinedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          <Translation  data="home.request_details"  />
        </Typography>
      </Breadcrumbs>
    </Box>
  );
}
