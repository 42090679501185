import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Avatar, Box, List, ListItem, ListItemText, Rating, Stack } from '@mui/material';
import { Typography } from '@mui/material';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import { Divider } from '@mui/material';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import RequestImg from '../image/RequestImg';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import SpeedIcon from '@mui/icons-material/Speed';
import Translation from '../../utils/Translation';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

const TransitionUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styleList = {
  py: 0,
  width: '100%',
  borderRadius: 2,
  borderColor: 'divider',
  backgroundColor: 'background.paper',
  };


export default function RequestDetails({data,open,handleClose}) {

    const optionsTime=[
        {
        "title": "6AM-12PM",
        "icon": <AccessTimeFilledIcon className='size-address' />
        },
        {
        "title": "12AM-6PM",
        "icon": <AccessTimeIcon className='size-address' />
        },
        {
        "title":  "6PM-12AM",
        "icon": <AccessTimeFilledIcon className='size-address' />
        },
        {
          "title":  "",
          "icon": ''
        },
        {
        "title": <Translation  data="request.urgent" />,
        "icon": <SpeedIcon className='size-address' />
        },
        {
        "title": <Translation  data="request.48_hours" />,
        "icon": <AccessTimeIcon className='size-address' />
        }
      ];
    
      const optionsPayment = [
          {
          "title":<Translation  data="request.payment_title_0" />,
          "description":<Translation  data="request.payment_desc_0" />
          },
          {
            "title":<Translation  data="request.payment_title_1" />,
            "description":<Translation  data="request.payment_desc_1" />
          },
          {
            "title":<Translation  data="request.payment_title_2" />,
            "description":<Translation  data="request.payment_desc_2" />
          }
      ];
    

      
  return (
    <Dialog
        open={open}
        TransitionComponent={TransitionUp}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        id="dialog-show-request"
      >
      
      <Typography className='p-1' variant='b' component="h1"><Translation  data="request.request_details" /></Typography>

         <Box>
          <DialogTitle variant='b' component="h2"><Translation  data="request.pck_description" /></DialogTitle>
          <DialogContent>
              <DialogContentText >
                {data?.description??''}
              </DialogContentText>
          </DialogContent>
   
          <DialogTitle variant='b' component="h3"><Translation  data="request.pck_size" /></DialogTitle>
           <DialogContent>
              <DialogContentText style={{ display: 'flex', alignItems: 'center' }}>
            {data?.package_size === 0 &&
              <>
                  <TwoWheelerIcon  className="request_icon" />
                  <Translation  data="request.pck_size_title_0" />
              </>
              }
            {data?.package_size === 1 &&
              <>
                  <DirectionsCarFilledIcon  className="request_icon" />
                  <Translation  data="request.pck_size_title_1" />
              </>
              }
              {data?.package_size === 2 &&
              <>
                  <LocalShippingIcon  className="request_icon" />
                  <Translation  data="request.pck_size_title_2" />
              </>
              }
              {data?.package_size === 3 &&
                <>
                    <CarRepairIcon  className="request_icon" />
                    <Translation  data="request.pck_size_title_3" />
                </>
              } 
              </DialogContentText>
          </DialogContent>

        
        </Box>

        <Divider  /> 

        <Box> 
          <DialogTitle variant='b' component="h2"> <Translation  data="request.pck_size_title_3" /> <Translation  data="request.delivery_location" /></DialogTitle>

          <DialogTitle variant='b' component="h3"><Translation  data="request.pickup_address" /></DialogTitle>
          <DialogContent>
              {
                data?.pickup_address_data?.friendly_name &&
                <Typography> <b><Translation  data="address.address_name" /> :</b>  {data?.pickup_address_data?.friendly_name??''} </Typography>
              }
              {
                data?.pickup_address_data?.mobile &&
                <Typography>  <b><Translation  data="address.address_mobile" /> : </b> {data?.pickup_address_data?.mobile??''} </Typography>
              }
              {
                data?.pickup_address_data?.city &&
                <Typography> <b><Translation  data="address.city" />  </b>  {data?.pickup_address_data?.city??''} </Typography>
              }

              {
                data?.pickup_address_data?.area &&
                <Typography> <b><Translation  data="address.area" /> </b> {data?.pickup_address_data?.area??''} </Typography>
              }

              {
                data?.pickup_address_data?.villa_bldng_no &&
                <Typography> <b><Translation  data="address.building" />  </b> {data?.pickup_address_data?.villa_bldng_no??''} </Typography> 
              } 
          </DialogContent>
          <DialogTitle variant='b' component="h3"><Translation  data="request.drop_address" /></DialogTitle>
          <DialogContent>

          {
                data?.drop_address_data?.friendly_name &&
                <Typography><b><Translation  data="address.address_name" /> :</b>  {data?.drop_address_data?.friendly_name??''} </Typography>
              }
              {
                data?.drop_address_data?.mobile &&
                <Typography>  <b><Translation  data="address.address_mobile" /> : </b> {data?.drop_address_data?.mobile??''} </Typography>
              }
              {
                data?.drop_address_data?.city &&
                <Typography> <b><Translation  data="address.city" />  </b>  {data?.drop_address_data?.city??''} </Typography>
              }

              {
                data?.drop_address_data?.area &&
                <Typography> <b><Translation  data="address.area" /> </b> {data?.drop_address_data?.area??''} </Typography>
              }

              {
                data?.drop_address_data?.villa_bldng_no &&
                <Typography> <b><Translation  data="address.building" />  </b> {data?.drop_address_data?.villa_bldng_no??''} </Typography> 
              } 
          </DialogContent>
        </Box>
        <Divider  />
        <Box>
          <DialogTitle variant='b' component="h2"> <Translation data="request.delivery_time"  /> </DialogTitle>
          
          <Box className="dialog-delivery-time" sx={{display:"inline-flex"}}>
              <DialogTitle variant='b' component="h3"> <Translation data="request.date"  /></DialogTitle>
              <DialogContent>
                  <DialogContentText  id="alert-dialog-slide-description" className='date-dialog'>
                      {data?.delivery_date??''}
                  </DialogContentText>
              </DialogContent>

              <DialogTitle variant='b' component="h3"><Translation data="request.time"  /></DialogTitle>
              <DialogContent>
              
                  <DialogContentText  id="alert-dialog-slide-description" className='date-dialog'>
                    {optionsTime[data?.deilvery_window]?.title} 
                  </DialogContentText>
              </DialogContent>
          </Box>
        
         </Box> 
         <Divider  />
        <Box>
          <DialogTitle variant='b' component="h2"> <Translation  data="request.payment" /></DialogTitle>
          <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                  {optionsPayment[data?.payment_method]?.title}
                  <br></br>
                  {optionsPayment[data?.payment_method]?.description}
              </DialogContentText>
          </DialogContent>
         </Box> 
         <Divider  />
        <Box>
        {
          (data?.special_care   || data?.instructions ||  data?.photo_url) &&  
          <DialogTitle variant='b' component="h2"><Translation  data="request.details"  /> </DialogTitle>
        }
          {data?.special_care  && 
          <>
         <DialogTitle variant='b' component="h3"> <Translation  data="request.special_care"  /> </DialogTitle>
                <DialogContent>

                    <DialogContentText id="alert-dialog-slide-description" style={{ display: 'flex', alignItems: 'center' }}>     
                        {data.special_care === "0" &&  
                            <>
                              <AcUnitIcon className="request_icon"  />
                              <Translation  data="request.require_refrigeration" />
                            </>
                            }
                      
                        {data.special_care === "1" && 
                        <>
                          <FreeBreakfastIcon className="request_icon" />
                          <Translation  data="request.fragile" />
                        </>
                        }
                        {data.special_care === "0|1" && 
                        <>
                          <AcUnitIcon className="request_icon"  />   <Translation  data="request.require_refrigeration" />
                            -  
                          <FreeBreakfastIcon className="request_icon" /> <Translation  data="request.fragile" />
                        </>
                        }
                    </DialogContentText>
                </DialogContent>
          </>
          }
    
          { data?.instructions && 
          <>
          <DialogTitle variant='b' component="h3"> <Translation data="request.instruction" /> </DialogTitle>
          <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {data?.instructions??''}
              </DialogContentText>
          </DialogContent>
          </>
          }
      

          {
            data?.photo_url  && 
            <>
            <DialogTitle variant='b' component="h3"> <Translation data="request.package_photo" /> </DialogTitle>
                    <DialogContent>
              
                        <RequestImg  url={data?.photo_url??''}  />

                    </DialogContent>
            </>
          }
        </Box>  

    <Divider  />
    
    {
      data?.offers?.length > 0 && 
      <>
      {data.offers.map((offer, index) => {
     
         return(
               (offer.OfferStatus === 1 &&
               <Box key={index}>
                <DialogTitle variant='b' component="h1"> <Translation data="offer.offer_details" /> </DialogTitle>
                <Box className='offer-now' key = {index}>
                
                <Box sx={{ display: 'flex' }} >
                    <Stack direction="row" spacing={2}>
                      <Avatar alt= {offer?.nickname} src={offer?.profile_image_url} />
                    </Stack>  
                    <Box sx={{p:1}}>
                      <div className='drive-name'> {offer?.nickname}</div> 
                    </Box>
                </Box>

                  <Box sx={{display: 'flex' }}>
                    {offer?.rating_value === null ?
                      <Rating name="read-only" readOnly/>
                      :
                      <Typography className='star-color' variant="p">        
                        {offer?.rating_value >= 1 ? <StarIcon  />:  <StarBorderIcon  />}
                        {offer?.rating_value >= 2 ? <StarIcon  />:  <StarBorderIcon  />}
                        {offer?.rating_value >= 3 ? <StarIcon  />:  <StarBorderIcon  />}
                        {offer?.rating_value >= 4 ? <StarIcon  />:  <StarBorderIcon  />}
                        {offer?.rating_value >= 5 ? <StarIcon  />:  <StarBorderIcon  />}
                      </Typography>
                    }
                  </Box>

                  <Box className="reference">
                  <Translation data="offer.reference" /> {offer?.offerId}
                  </Box>
            
                  <Box sx={{pb:1 }}>
                    <List sx={styleList}>
                      <ListItem>
                          <ListItemText primary=<Translation data="offer.value" />  />
                        <Typography>{(offer?.offerValueWihtoutServiceCharges)? offer?.offerValueWihtoutServiceCharges :  offer?.offerValue} <Translation data="offer.aed" /> </Typography>
                      </ListItem>
                      <Divider variant="middle" component="li" />
                      <ListItem>
                          <ListItemText primary=<Translation data="offer.service_charges" /> />
                          <Typography> {((offer?.offerValueWihtoutServiceCharges)? offer?.offerValueWihtoutServiceCharges :  offer?.offerValue) * offer?.offer_service_charges_percentage} <Translation data="offer.aed" /> </Typography>
                      </ListItem>
                      <Divider variant="middle" component="li" />
                      <ListItem>
                          <ListItemText primary=<Translation data="offer.total" /> />
                          <Typography> {offer?.campaign_details ? offer?.campaign_details?.newAmount :  offer?.offer_value_total} <Translation data="offer.aed" /></Typography>
                      </ListItem>
                      <Divider variant="middle" component="li" />
                      <ListItem>
                        <ListItemText primary=<Translation data="offer.chargeable_amount" /> />
                        <Typography> {offer?.campaign_details ? offer?.campaign_details?.newAmount :  offer?.offer_value_total} <Translation data="offer.aed" /></Typography>
                    </ListItem>
                    </List>
                  </Box>

                  <Divider  />
                </Box>
               </Box>
              
              ) 
            );
         
         })
      }
      </>
      }
     
        <DialogActions className='p-1' >
          <Button  variant="contained" onClick={handleClose}><Translation data="request.close" /> </Button>
        </DialogActions> 
        
      </Dialog>
  )
}
