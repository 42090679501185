import { auth } from '../../config/firebase-config';

import { RecaptchaVerifier, signInWithPhoneNumber, signInWithCredential,PhoneAuthProvider,onAuthStateChanged,signOut} from 'firebase/auth';


export const initAuthObserver = (callback) => {
  // This function will be called whenever the authentication state changes
  onAuthStateChanged(auth, (user) => {
    callback(user); 
  });
};




// the first function that will be called when the user clicks on the send OTP button
// if did not throw any error, it will return the verificationId front end should open otp modal and send the
// entered otp to the verifyOtp function
// expected responses are: 
// 1. verificationId: string
// 2. FirebaseError: object error.message will contain the the message
export const sendOtp = async (phoneNumber) => {

  try {
    // if captcha is not initialized, initialize it 
    if(!window.recaptchaVerifier){
        window.recaptchaVerifier = new RecaptchaVerifier(auth,'recaptcha-container', {
            size: 'invisible',
            callback: (response) => {
              // Handle successful reCAPTCHA verification here
              console.log('reCAPTCHA verified successfully');
            },
            'expired-callback': () => {
              // Handle reCAPTCHA expiration here
              console.log('reCAPTCHA expired, please retry');
            }
            });
            window.recaptchaWidgetId = window.recaptchaVerifier.render().then(widgetId => {
              window.recaptchaWidgetId = widgetId;
            }).catch(error => {
              console.error('Error rendering reCAPTCHA:', error);
            });
    }
    const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier);
    return confirmationResult.verificationId;
  } catch (error) {
    const errorMessage = handleFirebaseError(error);
    throw new Error(errorMessage);
  }
};

// the second function that will be called when the user clicks on the verify OTP button
// if did not throw any error, it will return the token string which can be used to authenticate the user
// expected responses are:
// 1. token: string
// 2. FirebaseError: object error.message will contain the the message

export const verifyOtp = async (verificationId, otp) => {
  try {
    // Verify OTP

    const authCredential = await PhoneAuthProvider.credential(verificationId, otp);
    const userCredential = await signInWithCredential(auth,authCredential);
    

    return userCredential.user.accessToken
  }  catch (error) {
    const errorMessage = handleFirebaseError(error);
    throw new Error(errorMessage);
  }
};

export const signOutUser = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    const errorMessage = handleFirebaseError(error);
    throw new Error(errorMessage);
  }
};

// function to handle firebase errors
const handleFirebaseError = (error) => {
    switch (error.code) {
        case 'auth/invalid-verification-code':
            console.log('Invalid verification code');
        return 'Invalid verification code';
        case 'auth/missing-verification-code':
            console.log('Missing verification code');
        return 'Missing verification code';
        case 'auth/invalid-phone-number':
            console.log('Invalid phone number');
        return 'Invalid phone number';
        default:
            console.error('Error verifying OTP:', error);
        return error.message;
    }
    }