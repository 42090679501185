import { useState,useEffect } from 'react'; 
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import { Link } from 'react-router-dom';
import AddressModal from '../modal/AddressModal';
import { deleteAddressAsync, setDataAddress,setStatusAddress } from '../../services/address/addressSlice';
import { useDispatch,useSelector } from 'react-redux';
import { updateAddressAsync } from '../../services/address/addressSlice';


export default function SelectAddress({destination,value,action}) {

  const [open, setOpen] = useState(false);
  const [addressId,setAddressId]=useState(null);
  const handleClose = () =>  setOpen(false);
  const auth = useSelector(state => state.auth);
  const dynamicState = useSelector(state => state.dynamicConfig);
  const address = useSelector(state => state.address);
  const dispatch= useDispatch();
  const [msg ,setMess] = useState(null);
  const [submit,setSubmit] = useState(false);
  


  const handleOpen = (fav_id) => {

    setOpen(true);
    setStatusAddress({status:"idle"})
    setSubmit(false);
    setMess(null);

    setAddressId(fav_id);
    let data =address.data.filter((val) => val.fav_id === fav_id);
    dispatch(setDataAddress({data}));
  };

  const handleOnSubmitDelete = (e) => {

    e.preventDefault();
    let data = { fav_id : addressId};
    dispatch(deleteAddressAsync({auth,dynamicState,data}));
    setSubmit(true);
  }

  const handleOnSubmit =((e,object) => {

    e.preventDefault();

    let point = object.coordinates.lat + "," + object.coordinates.lng
    let data = {
      coordinates: point,
      mobile: object.mobile,
      friendly_name: object.friendlyName,
      villa_bldng_no: object.buildingNo,
      street: object.street,
      area: object.area,
      location_address: "("+point+")",
      city: "",     
    };
   
    dispatch(updateAddressAsync({auth,dynamicState,data,addressId}));
    setSubmit(true);

  });


  
  useEffect(()=>{ 

    if(submit === true){

      if(address.status === "succeeded"){
        setMess("Add Successfully");
        handleClose();
      }
      
      address.status === "failed" &&  setMess(address.error);
    }
   
  },[address.status]);

  
  return (
    <>
         <AddressModal 
            open={open} 
            handleClose={handleClose} 
            handleOnSubmitDelete={handleOnSubmitDelete} 
            addressData={address.addressData} 
            update={true}
            error={address.error}
            status={address.status}
            msg={msg}
            handleOpen={handleOpen}
            handleOnSubmit={handleOnSubmit}
            submit={submit}   
            />

        


        <Box sx={{ width: '100%' }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

      {address.data && address.data.map((address,idx)=>{
            if(destination !== address.fav_id){
                  return <Grid md={4} sm={6} xs={12} id={address.fav_id}  key={idx}>
                    <Link to='/add_request' 
                    className='text-undecoration' onClick={() => dispatch(action(address.fav_id))}>
              
                  <Box className="select-address">
                      <Container  className={`box-request ${address.fav_id===value ? 'active':''}`}>
                        <Grid container spacing={3}>
                            <Grid>
                                <ContactsOutlinedIcon className='size-address'  />
                            </Grid>

                            <Grid  xs={10} className="address-name"  >
                          
                              <Typography variant="b" component="h3">
                                {address.address_data?.friendly_name??''}
                              </Typography>
                          
                            </Grid>


                        </Grid>

                        <Box component='ul'>

                          <Typography variant="li" component="li">
                            Mobile Number: {address.address_data?.mobile??''}
                          </Typography>

                        </Box>
                      </Container>  
                  </Box>
                    </Link>
                  </Grid>
            }
        })}
      
      </Grid>
    </Box>
    </>
   
  
  )
}
