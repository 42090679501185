import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import HeaderChat from './HeaderChat';
import { Box } from '@mui/material';
import { useEffect,useState } from 'react';
import { collection, query, onSnapshot  } from 'firebase/firestore';
import { db } from '../../config/firebase-config';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { setIsLoadingChat,setChatList } from '../../services/chat/chatSlice';
import { useDispatch } from 'react-redux';

export default function ChatList({changeWidget,handleChat }) {

    const {user} = useSelector(state => state.auth);
    const {isLoadingChat,chatList,readOnly} = useSelector(state => state.chat);
    const dispatch = useDispatch();

    useEffect(() => {

            const q = query(collection(db,`/users/${user.uid}/chat_stats`));

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
              const list = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
              }));
              dispatch(setChatList(list.reverse()));
            
              dispatch(setIsLoadingChat(false));
            });
            return () => unsubscribe();
   
      }, []);


    return (
        <>
       {isLoadingChat ?  <CircularProgress  className='chat-loading' />  :
        <Box  className="list-chat">
         <HeaderChat  changeWidget={changeWidget} name="All Chat" type="list" />
         <Box>
                {chatList.length > 0 && chatList.map((chat,idx)=>{ 
                    return( 
                        <Box   key={idx}>
                            {chat.readOnly === readOnly &&
                                <List 
                                sx={{ width: '100%' , bgcolor: 'background.paper' }}>
                                    <ListItemButton 
                                    onClick = {()=>handleChat("single-chat",chat)}>
                                        <ListItem alignItems="flex-start">
                                            <ListItemAvatar>
                                            <Avatar alt={chat?.nickname ?chat?.nickname:'' } src={chat?.senderProfileImage} />
                                            </ListItemAvatar>
                                            <ListItemText
                                            primary={chat?.nickname?chat?.nickname:''}
                                            secondary={
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                {chat?.reqDesc}
                                                </Typography>
                                            }
                                            />
                                        </ListItem>
                                    </ListItemButton>
                                    <Divider  component="li" />
                                </List>
                            }
                        </Box>
                        )
                    })
                }
         </Box>
     
        </Box>
        }
        </>
       
 
    );
}

