import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { sendOtp, verifyOtp,signOutUser } from "./auth_service";
import { initAuthObserver } from "./auth_service";

const initialState = {
    isLoading: false,
    isClick:false,
    status: 'idle',
    verificationId: null,
    accessToken: null,
    phoneNumber: '',
    otp: null,
    error: null,
    user: null,
  
    statusVerify: 'idle',
    isLoadingVerify: false,
    errorVerify: null,

    statusLogout:'idle',
};

export  const prefix = '+'

export const  sendOtpRequest = createAsyncThunk("type/sendOtpRequest", async (data) => {
    data.phoneNumberInput = prefix + data.phoneNumberInput; // add plus in react js
    const verificationId = await sendOtp(data.phoneNumberInput); // Assuming sendOtp is an async function

    return verificationId;
});

export const  verifyOtpAsync = createAsyncThunk("type/verifyOtpAsync", async (data) => {
  const accessToken = await verifyOtp(data.verificationId, data.otpInput); // Assuming verifyOtp is an async function

  return accessToken;
});

export const  logoutRequest = createAsyncThunk("type/logoutRequest", async () => {
    const logout =await signOutUser();

    return logout;
});

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser:  (state, action) => {
            state.user = action.payload.user;
            state.accessToken =  action.payload.accessToken;
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload.isLoading;
        },
        setStatus: (state, action) => {
          state.status = action.payload.status;
        },
        setStatusVerify: (state,action) => {
          state.statusVerify = action.payload.statusVerify;
        },
        setError: (state,action) => {
          state.error = action.payload.error;
        },
        setErrorVerify: (state,action) => {
          state.errorVerify = action.payload.errorVerify;
        },setIsClick :(state,action) => {
          state.isClick = action.payload;
        }
    },
    extraReducers: builder => {
        builder
          .addCase(sendOtpRequest.pending, (state, action) => {
            state.status = 'loading';
            state.isClick = true;
            state.error = null;
            state.errorVerify = null;
          })
          .addCase(sendOtpRequest.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.statusLogout = 'idle';
            state.error = null;
            state.errorVerify = null;
            state.isClick = false;
            state.verificationId=action.payload
          })
          .addCase(sendOtpRequest.rejected, (state, action) => {
            state.status = 'failed';
            state.isClick = false;
            state.error = action.error.message;
            state.errorVerify = null;
            console.log("Error in sendOtpRequest" + action.error.message);
          }).addCase(verifyOtpAsync.pending, (state, action) => {
            state.statusVerify = 'loading';
            state.isClick = true;
            state.errorVerify = null;
          })
          .addCase(verifyOtpAsync.fulfilled, (state, action) => {
            state.statusVerify = 'succeeded';
            state.isClick = false;
            state.errorVerify = null;
            state.verificationId=action.payload.verificationId;
            state.otp=action.payload.otp;
          })
          .addCase(verifyOtpAsync.rejected, (state, action) => {
            state.statusVerify = 'failed';
            state.isClick = false;
            state.errorVerify = action.error.message;
            console.log("Error in verifyOtpAsync" + action.error.message);
          })
          .addCase(logoutRequest.pending, (state, action) => {
            state.statusLogout = 'loading';
            state.isClick = true;
            state.error = null;
          })
          .addCase(logoutRequest.fulfilled, (state, action) => {
            state.statusLogout = 'succeeded';
            state.status = 'idle';
            state.isClick = false;
            state.error = null;
            state.user = null;
            state.accessToken=null;
            state.isLogout = true;
          })
          .addCase(logoutRequest.rejected, (state, action) => {
            state.statusLogout = 'failed';
            state.isClick = false;
            state.error = action.error.message;
            console.log("Error in logoutRequest" +action.error.message);
          })
      }
});

export const initAuthObserverAsync =  () => (dispatch) => {
    dispatch(setLoading({ isLoading: true }));
    initAuthObserver(async (user) => {
        if (user) {
            await user.getIdToken() 
            .then(function(idToken) {
              dispatch(setUser({ user: user,accessToken:idToken }));
            }).catch(function(error) {
               console.log("error : " + error)
            });
        }else{
            dispatch(setUser({ user: null,accessToken:null }));
        }
        dispatch(setLoading({ isLoading: false }));
    });

};




export const {  verifyOtpSuccess,setUser,setLoading,setLogOut,setStatus,
  setStatusVerify,setError,setErrorVerify,setIsClick } = authSlice.actions;
export default authSlice.reducer;
