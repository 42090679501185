export const  differentDate = (date) => {
    
    let arr=[];
    let diffrentDate = (new Date() - new Date(date));
    arr["days"] =  Math.floor(diffrentDate / (1000 * 60 * 60 * 24));
    arr["hours"] = Math.floor(diffrentDate / (1000 * 60 * 60));
    arr["minutes"] = Math.floor(diffrentDate / (1000 * 60));
    arr["seconds"] = Math.floor(diffrentDate / (1000));
   
    return arr;
}

export const getStripPk =() => {

    return "pk_live_51KgANnKpR5JpJdcY1GM1aCsQpFstSt6XWxUmpHg1B44TDTHO3vIU1fUlq25DAxHifBAkGLoClB3gTq9W1L5Y4wel00TcH8YlXB"
}

export const getTestStripPk = () => {
    return "pk_test_51KgANnKpR5JpJdcYDlX5SLKV02eWDAhiwYqHS7IPdzClwOzpsjfHlcVUqhfm5gbe88BWQEbCR8GqJ9coaXceYKYa00TlA3KvVJ"
}

export const getLanguage = () => {

   return document.getElementsByTagName("html")[0].lang

}

export const googleMapsApiKey = () => {
    return "AIzaSyA5qj88Gbv3jCtM6V5NoPDHwo13Xu95OS4"
}



export const keyLang = ["en-US","ar"];
export const loadArabicStyle = (href) => {
    document.getElementsByTagName("html")[0].lang = keyLang[1];
    document.getElementsByTagName("html")[0].classList.add("arabic");
  };
  
export const removeArabicStyle = () => {
    document.getElementsByTagName("html")[0].lang = keyLang[0];
    document.getElementsByTagName("html")[0].classList.remove("arabic");
  };

export const loadStyle = (lang) =>{

    if(lang ===  keyLang[1]) // set file arabic
     loadArabicStyle()
    else
     removeArabicStyle()

  }

export const formatDate = (date,plusDay) => {

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    let day =  String(parseInt(String(date.getDate()).padStart(2, '0')) + plusDay);
  
    if(day.length === 1) // for make day as 02-  03
      day = "0"+ day;
  
    return `${year}-${month}-${day}`;
  };



export const getNotifyId = () => {
  const VITE_APP_VAPID_KEY = "BPwSFf-y3r5rhMvVI3aoVYD4g5FOcmCgxxoe3y22O4oFuyUt110S2VllAZAuNmUNEVKwjDpMmymaoVhnGK2bxN4";

  return VITE_APP_VAPID_KEY
}
  

export const NoIndexPage = () => {

    // change meta title
    if(localStorage.getItem("i18nextLng") === "ar") {
      document.getElementById("project_name").innerHTML = "ديليفرإت";
    }else{
      document.getElementById("project_name").innerHTML = "Deliverit";
    }

    const metaTag = document.createElement('meta');
    metaTag.name = 'robots';
    metaTag.content = 'noindex, nofollow';
    document.head.appendChild(metaTag);   

     // Cleanup: Remove the meta tag when the component unmounts
     return () => {
      document.head.removeChild(metaTag);
    };
}

export const IndexPage = () => {

   // change meta title
  document.getElementById("project_name").innerHTML = "DeliverIt Web Application Login";

  // remove meta nofollow
  let metaTags = document.querySelectorAll('meta[name="robots"]');
  for(var i=0 ; i< metaTags.length ; i++)
    document.head.removeChild(metaTags[i]);
}


