import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import 'react-chat-widget/lib/styles.css';
import { useSelector } from 'react-redux';

export default function ButtonChat({actionHandle,open}) {

  const {badgeContent} = useSelector(state => state.chat);

  return (
    <Box className="rcw-widget-container rcw-close-widget-container ">
    <Button onClick={actionHandle(!open)} type="button" className="rcw-launcher chat-button" aria-controls="rcw-chat-container">
        { badgeContent > 0  && <Typography className="rcw-badge">{badgeContent}</Typography>}
        <img loading="lazy"  src= {require('../../assets/image/layout/chat.png')} className='chat-img' />
    </Button>
    </Box>
  )
}
