import React from 'react'
import { Box } from '@mui/material'
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import AcUnitIcon from '@mui/icons-material/AcUnit';
export default function SpcialCareLabel(props) {
  return (
    <Box style={{ display: 'flex', alignItems: 'center' }}>
     {props.value === "0" && <AcUnitIcon  style={{ marginRight: 8 }} />}
     {props.value === "1" && <FreeBreakfastIcon  style={{ marginRight: 8 }} />}  
      {props.title}           
    </Box>
  )
}
